export const PROFILE_CARD_CONSTANTS = {
  TITLE: "Profile",
  MAIN_PAGE: "/profile",
  UPDATE_ITEM: {
    link: "/db/admin/update/",
  },
  INPUTS: [
    {
      id: "0",
      name: "First Name",
      key: "first_name",
      // type: "text",
      placeholder: "First Name",
    },
    {
      id: "1",
      name: "Last Name",
      key: "last_name",
      // type: "text",
      placeholder: "Last Name",
    },
    {
      id: "2",
      name: "Email (non-editable)",
      key: "email",
      // type: "number",
      placeholder: "Email",
    },
    {
      id: "3",
      name: "Phone",
      key: "phone",
      // type: "number",
      placeholder: "Phone",
    },
    {
      id: "4",
      name: "Old Password",
      key: "old_password",
      // type: "text",
      placeholder: "Old Password",
    },
    {
      id: "5",
      name: "New Password (at least 8 characters long)",
      key: "new_password",
      // type: "number",
      placeholder: "New Password",
    },
  ],
};

export const PERMISSION_CARD_CONSTANTS = {
  TITLE: "Invite New Admin",
  MAIN_PAGE: "/permissions",
  ADD_ITEM: {
    link: "/db/admin/add/",
  },
  TABLE: {
    header: ["First", "Last", "Email", "Permission (Not Available)"],
    key: ["first_name", "last_name", "email", "level"],
  },
  // FORMULA: {
  //   first_name: "",
  //   email: "",
  //   level: "",
  // },
  INPUTS: [
    {
      id: "0",
      name: "First Name",
      key: "first_name",
      // type: "text",
      placeholder: "First Name",
    },
    {
      id: "1",
      name: "Last Name",
      key: "last_name",
      // type: "text",
      placeholder: "Last Name",
    },
    {
      id: "2",
      name: "Email",
      key: "email",
      // type: "text",
      placeholder: "Email",
    },
    {
      id: "3",
      name: "Permission Level 1 - 5 (Max: 5)",
      key: "level",
      // type: "number",
      placeholder: "Permission Level",
    },
  ],
};
