import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import ListSubheader from "@mui/material/ListSubheader";
import DescriptionIcon from "@mui/icons-material/Description";
import EditIcon from "@mui/icons-material/Edit";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Cookies from "js-cookie";
import { useNavigate, Link } from "react-router-dom";
import url from "./url.component";
import { StyledTableCell } from "./globalStyles.component";
import { StyledTableRow } from "./globalStyles.component";
import { StyledIconButton } from "./globalStyles.component";
import Pagination from "@mui/material/Pagination";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Hidden from "@mui/material/Hidden";
import Webcam from "react-webcam";
import jsQR from "jsqr";

function BaseList(props) {
  const navigate = useNavigate();
  const [level, setLevel] = useState(0);
  const {
    data, // variables
    options, // constants
    // tab settings
    hasTab,
    setTab,
    // new, upload, download btns
    hasNewBtn,
    hasDownloadBtn,
    hasUploadBtn,
    // search settings
    hasSearchBar,
    // table settings
    hasTable,
    page,
    setPage,
    totalPages,
    hasEnableBtn,
    entityStatus,
    setEntityStatus,
  } = props;

  const [tabIndex, setTabIndex] = React.useState(0);
  const [showCamera, setShowCamera] = useState(false);
  const webcamRef = useRef(null);
  const searchInputRef = useRef("");

  useEffect(() => {
    if (hasSearchBar) {
      searchInputRef.current?.focus();
    }
  }, [hasSearchBar]);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
    setTab(options.TAB.value[newValue]);
  };

  const handleScanQRCode = () => {
    setShowCamera(true);
  };

  const isUUIDv4 = (str) => {
    const uuidv4Regex =
      /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
    return uuidv4Regex.test(str);
  };

  const handleKeyDown = () => {
    const inputValue = searchInputRef.current.value;
    // console.log(event.target.value);
    if (isUUIDv4(inputValue)) {
      navigate(inputValue.toLowerCase());
      // fetch(url(`/db/buyback/scan-code/?uuid=${screen_uuid}`), {
      //   method: "GET",
      //   credentials: "include",
      // })
      //   .then((res) => {
      //     if (res.status !== 200) {
      //       return;
      //     }
      //     return res.json();
      //   })
      //   .then((res) => {
      //     if (res.message) {
      //       navigate(res.message);
      //     }
      //   })
      //   .catch((error) => console.error(error));
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleDownload = (param) => {
    const timestamp = new Date().toLocaleString().replace(/[ ,:-]/g, "");
    const filename = param + `${options.TITLE}_${timestamp}.csv`;
    fetch(url(options.FILE_DOWNLOAD.link), {
      method: "POST",
      headers: { "X-CSRFToken": Cookies.get("csrftoken") },
      credentials: "include", // MUST
    })
      .then((res) => res.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = filename;
        link.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error occurred during file download:", error);
      });
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (!file) {
      console.log("No file selected.");
      return;
    }
    const fileType = file.type;
    if (
      (fileType !== "text/csv" &&
        fileType !==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") ||
      !file.name.startsWith(options.TITLE)
    ) {
      console.log("Please select a CSV / XLSX file in the correct format.");
      return;
    }
    handleDownload("Backup_");
    const formData = new FormData();
    formData.append("file", file);
    fetch(url(options.FILE_UPLOAD.link), {
      method: "POST",
      headers: { "X-CSRFToken": Cookies.get("csrftoken") },
      credentials: "include", // MUST
      body: formData,
    }).then((res) => {
      if (res.status !== 200) {
        console.log("Inspect Browser Network Logs");
      } else {
        window.location.reload();
      }
    });
  };

  const handlePublishStatus = (event, uuid) => {
    fetch(url(options.CHANGE_STATUS.link), {
      method: "PUT",
      headers: { "X-CSRFToken": Cookies.get("csrftoken") },
      credentials: "include", // MUST
      body: JSON.stringify({ uuid: uuid }),
    })
      .then((res) => res.json())
      .then((res) => {
        const updatedStatus = { ...entityStatus };
        updatedStatus[uuid] = !updatedStatus[uuid];
        setEntityStatus(updatedStatus);
      })
      .catch((error) => {
        console.error("Error occurred during status update:", error);
      });
  };

  const handleCapture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    if (imageSrc) {
      const image = new Image();
      image.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        const context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, canvas.width, canvas.height);
        const imageData = context.getImageData(
          0,
          0,
          canvas.width,
          canvas.height
        );
        const code = jsQR(imageData.data, imageData.width, imageData.height);

        if (code) {
          console.log("QR Code:", code.data);
          setShowCamera(false);
          const newUrl = `/buyback/status/${code.data}`;
          navigate(newUrl);
        } else {
          console.log("No QR Code detected. Please try again.");
        }
      };
      image.src = imageSrc;
    }
  };

  useEffect(() => {
    fetch(url(`/db/admin/get-level/`), {
      method: "GET",
      credentials: "include",
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setLevel(res.level);
        // console.log(res.level);
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <Typography
          sx={{
            fontSize: {
              xs: "24px",
              md: "32px",
            },
          }}
        >
          {options.TITLE}
        </Typography>
        {hasTab && (
          <Box>
            <Tabs value={tabIndex} onChange={handleTabChange}>
              {options.TAB.key.map((item, index) => (
                <Tab
                  key={index + item}
                  value={item}
                  label={options.TAB.value[item]}
                />
              ))}
            </Tabs>
          </Box>
        )}
        <Box sx={{ display: "flex", gap: 2 }}>
          {level >= 4 && hasNewBtn && (
            <Button
              variant="contained"
              component={Link}
              to={options.ADD_ITEM.link}
            >
              {options.ADD_ITEM.name}
            </Button>
          )}

          {level >= 4 && hasUploadBtn && (
            <StyledIconButton component="label" color="primary" size="medium">
              <input
                type="file"
                accept=".csv, .xlsx"
                hidden
                onChange={handleFileSelect}
              />
              <FileUploadIcon />
            </StyledIconButton>
          )}
          {level >= 4 && hasDownloadBtn && (
            <StyledIconButton
              color="primary"
              size="medium"
              onClick={() => handleDownload("")}
            >
              <FileDownloadIcon />
            </StyledIconButton>
          )}
        </Box>
      </Box>
      {hasSearchBar && (
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            marginBottom: "20px",
            gap: 3,
          }}
        >
          <TextField
            sx={{ flex: 1 }}
            label="Search"
            variant="outlined"
            inputRef={searchInputRef}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleKeyDown();
              }
            }}
          />
          <Hidden smDown>
            <FormControl sx={{ flex: 0.5 }}>
              <InputLabel htmlFor="grouped-select">Category</InputLabel>
              <Select defaultValue="" id="grouped-select" label="Grouping">
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <ListSubheader>Category 1</ListSubheader>
                <MenuItem value={1}>Option 1</MenuItem>
                <MenuItem value={2}>Option 2</MenuItem>
                <ListSubheader>Category 2</ListSubheader>
                <MenuItem value={3}>Option 3</MenuItem>
                <MenuItem value={4}>Option 4</MenuItem>
              </Select>
            </FormControl>
          </Hidden>
          {/* <Button variant="contained" color="primary">
          Search
        </Button> */}
          <Hidden smUp>
            <Button
              variant="contained"
              color="primary"
              onClick={handleScanQRCode}
            >
              Scan QR Code
            </Button>
            {showCamera && (
              <Box>
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  videoConstraints={{ facingMode: "environment" }}
                />
                <Button variant="contained" onClick={handleCapture}>
                  Capture QR Code
                </Button>
              </Box>
            )}
          </Hidden>
        </Box>
      )}

      {level >= 4 && hasTable && (
        <Box>
          <TableContainer component={Paper}>
            <Table aria-label="custom pagination table">
              <TableHead>
                <TableRow>
                  {options.TABLE.header.map((item, index) => (
                    <StyledTableCell key={index + item}>{item}</StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row) => (
                  <StyledTableRow key={row.uuid}>
                    {options.TABLE.key.map((item, index) => (
                      <StyledTableCell key={index + item}>
                        {row[item] && row[item].toString()}
                      </StyledTableCell>
                    ))}
                    <StyledTableCell>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 15,
                        }}
                      >
                        {false && (
                          <StyledIconButton color="primary" size="medium">
                            <DescriptionIcon />
                          </StyledIconButton>
                        )}
                        <StyledIconButton
                          color="primary"
                          size="medium"
                          component={Link}
                          to={options.EDIT_ITEM.link + row.uuid}
                        >
                          <EditIcon />
                        </StyledIconButton>
                        {hasEnableBtn && (
                          <Button
                            variant="contained"
                            color={entityStatus[row.uuid] ? "primary" : "error"}
                            onClick={(e) => handlePublishStatus(e, row.uuid)}
                          >
                            {entityStatus[row.uuid] ? "Disable" : "Enable"}
                          </Button>
                        )}
                        {/* {!hasEnableBtn && <Button variant="contained">Deactivate</Button>} */}
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            size="large"
            count={totalPages}
            page={page}
            onChange={handlePageChange}
            sx={{ marginTop: "20px" }}
          ></Pagination>
        </Box>
      )}
    </Box>
  );
}

export default BaseList;
