import React, { useEffect, useState } from "react";
import url from "../common/url.component";
import AuthWrapper from "../common/authWrapper.component";
import BaseList from "../common/BaseList";
import { CUSTOMER_LIST_CONSTANTS } from "./options";

function CustomerList() {
  const [isLoading, setIsLoading] = useState(true);
  const isLoading2 = AuthWrapper();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [data, setData] = useState(null);
  const [tab, setTab] = useState("All");
  const [entityStatus, setEntityStatus] = useState({});

  useEffect(() => {
    fetch(url(`/db/customers/?page=${page}&type=${tab}`), {
      method: "GET",
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        // console.log(res.customers);
        setData(res.customers);
        setEntityStatus(
          res.customers.reduce((statusObj, item) => {
            statusObj[item.uuid] = item.entity_status;
            return statusObj;
          }, {})
        );
        setTotalPages(res.total_pages);
        setIsLoading(false);
      })
      .catch((error) => console.error(error));
  }, [page, tab]);

  if (isLoading2 || isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <BaseList
      data={data}
      options={CUSTOMER_LIST_CONSTANTS}
      hasTab={true}
      setTab={setTab}
      hasNewBtn={true}
      hasDownloadBtn={true}
      hasUploadBtn={false}
      hasSearchBar={true}
      hasTable={true}
      page={page}
      setPage={setPage}
      totalPages={totalPages}
      hasEnableBtn={true}
      entityStatus={entityStatus}
      setEntityStatus={setEntityStatus}
    />
  );
}
export default CustomerList;
