import { TextField, Box, Button } from "@mui/material";
import { StyledTicketPageBox } from "./globalStyles.component";
import { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import url from "./url.component";

const MIN_SEARCH_LENGTH = 3;
const MAX_OPTIONS = 20;

export default function CustomerSelector({
  setCustomer,
  onNewButtonClick,
  createBtn,
}) {
  const [customerName, setCustomerName] = useState("");
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleCustomerNameChange = (event) => {
    const newValue = event.target.value;
    setCustomerName(newValue);
  };

  const handleAutocompleteChange = (event, selectedOption) => {
    setSelectedOption(selectedOption);
    if (selectedOption) {
      const parts = selectedOption.split("@");
      setCustomer(parts.length > 1 ? parts.pop() : "");
    } else {
      setCustomer("");
    }
  };

  useEffect(() => {
    // Function to fetch customer name suggestions from Django backend
    async function fetchCustomerSuggestions() {
      try {
        if (customerName.length >= MIN_SEARCH_LENGTH) {
          const response = await fetch(
            url(`/db/customers/search/?query=${customerName}`)
          );
          const data = await response.json();
          setOptions(data.customers.slice(0, MAX_OPTIONS)); // Assuming the response is an array of customer names
        } else {
          setOptions([]);
        }
      } catch (error) {
        console.error("Error fetching customer suggestions:", error);
      }
    }

    // Fetch customer suggestions when customerName changes
    if (customerName) {
      fetchCustomerSuggestions();
    } else {
      setOptions([]); // Clear the suggestions when input is empty
    }
  }, [customerName]);

  return (
    <Box style={{ display: "flex", width: "100%", paddingBottom: "1%" }}>
      <StyledTicketPageBox>Customer</StyledTicketPageBox>
      <Autocomplete
        id="customer-autocomplete"
        options={options}
        getOptionLabel={(option) => option}
        style={{ flex: 1 }}
        value={selectedOption}
        onChange={handleAutocompleteChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label="At least 3 characters for customer / phone / company ..."
            variant="outlined"
            onChange={handleCustomerNameChange}
          />
        )}
      />
      {createBtn && (
        <Button
          variant="contained"
          color="primary"
          size="medium"
          onClick={onNewButtonClick}
          disabled={!selectedOption}
        >
          Create
        </Button>
      )}
    </Box>
  );
}
