import React, { useEffect, useState, useCallback } from "react";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
// import Cookies from "js-cookie";
// import { Link } from "react-router-dom";
import { StyledTicketPageBox } from "../common/globalStyles.component";
import InventorySelector from "../common/inventory-selector";
import LineItemView from "./line-item-view";
import { Typography } from "@mui/material";
import Cookies from "js-cookie";
import url from "../common/url.component";
import { UNLOCKED_STATUS } from "./options";

function LineItemContainer(props) {
  const { uuid, data } = props.state;
  const { setData } = props;
  // const [message, setMessage] = useState("");
  const [inventoryUUID, setInventoryUUID] = useState(null);
  const [inventoryName, setInventoryName] = useState("");
  const [selected, setSelected] = useState(false);
  // const inputRefs = inputs.map(() => createRef());
  const [description, setDescription] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [price, setPrice] = useState(0);
  // const navigate = useNavigate();
  // const [ticketStatusValue, setTicketStatusValue] = useState(null);

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleQuantityChange = (event) => {
    setQuantity(event.target.value);
  };

  const handlePriceChange = (event) => {
    setPrice(event.target.value);
  };

  const handleSetInventoryUUID = useCallback((selectedInventory) => {
    // console.log(selectedInventory);
    setInventoryUUID(selectedInventory);
  }, []);

  const handleSetSelected = useCallback((selectedSelected) => {
    // console.log(selectedSelected);
    setSelected(selectedSelected);
  }, []);

  useEffect(() => {
    if (selected) {
      fetch(url(`/db/inventory/get-price/?uuid=${inventoryUUID}`), {
        method: "GET",
        credentials: "include",
      })
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          // console.log(res.message);
          setPrice(res.message);
        })
        .catch((error) => console.error(error));
    }
  }, [inventoryUUID, selected]);

  const handleAddProductToTicket = () => {
    fetch(url(`/db/tickets/add-line-item/`), {
      method: "PUT",
      headers: { "X-CSRFToken": Cookies.get("csrftoken") },
      credentials: "include",
      body: JSON.stringify({
        ticket_uuid: uuid,
        inventory_uuid: inventoryUUID,
        inventory_name: inventoryName,
        description: description,
        quantity: quantity,
        price: price,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log("inventoryUUID = " + inventoryUUID);
        console.log("inventoryName = " + inventoryName);
        setData(res.data);
        setInventoryName("");
        setInventoryUUID(null);
        setSelected(false);
        // console.log(res.data);
        // setTicketStatusValue(newValue);
      })
      .catch((error) => console.error(error));
  };

  return (
    <Card sx={{ flexGrow: 1 }}>
      <CardHeader
        title="Ticket Line Items"
        style={{ backgroundColor: "#899499", color: "white" }}
      />
      <CardContent>
        <Grid container spacing={2}>
          {UNLOCKED_STATUS.includes(data.status) && (
            <Grid item container direction="column" xs={5}>
              <Grid item>
                <Typography variant="h6">Add New Item</Typography>
              </Grid>
              <Grid item>
                <Box
                  style={{
                    display: "flex",
                    width: "100%",
                    paddingBottom: "1%",
                  }}
                >
                  <InventorySelector
                    state={{ ...props.state, inventoryName, setInventoryName }}
                    setInventoryUUID={handleSetInventoryUUID}
                    setSelected={handleSetSelected}
                  />
                </Box>
              </Grid>
              <Grid item>
                <Box
                  style={{
                    display: "flex",
                    width: "100%",
                    paddingBottom: "1%",
                  }}
                >
                  <StyledTicketPageBox>Description</StyledTicketPageBox>
                  <TextField
                    id="name-input"
                    variant="outlined"
                    fullWidth
                    onChange={handleDescriptionChange}
                  />
                </Box>
              </Grid>
              <Grid item>
                <Box
                  style={{
                    display: "flex",
                    width: "100%",
                    paddingBottom: "1%",
                  }}
                >
                  <StyledTicketPageBox>Quantity</StyledTicketPageBox>
                  <TextField
                    id="name-input"
                    variant="outlined"
                    fullWidth
                    defaultValue={1}
                    inputProps={{
                      type: "number",
                      step: 1,
                      min: 1,
                      max: 999,
                    }}
                    onChange={handleQuantityChange}
                  />
                </Box>
              </Grid>
              <Grid item>
                <Box
                  style={{
                    display: "flex",
                    width: "100%",
                    paddingBottom: "1%",
                  }}
                >
                  <StyledTicketPageBox>Price</StyledTicketPageBox>
                  <TextField
                    id="name-input"
                    variant="outlined"
                    fullWidth
                    inputProps={{ type: "number", step: 0.01, min: 0.0 }}
                    onChange={handlePriceChange}
                    value={price !== null ? price : ""}
                  />
                </Box>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ backgroundColor: "green" }}
                  onClick={handleAddProductToTicket}
                  // disabled={inventory === "" || !quantity || price === ""}
                >
                  Add Item to Ticket
                </Button>
              </Grid>
            </Grid>
          )}
          <Grid
            item
            container
            direction="column"
            xs={UNLOCKED_STATUS.includes(data.status) ? 7 : 12}
          >
            <Grid item>
              <LineItemView state={props.state} setData={props.setData} />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default LineItemContainer;
