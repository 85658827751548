import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

function ConfirmationDialog(props) {
  const { isOpen, onClose, onConfirm } = props.state;

  return (
    <Dialog open={isOpen} onClose={() => onClose(false)}>
      <DialogTitle>{"Confirmation"}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {"Are you sure you want to proceed?"}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        style={{ display: "flex", justifyContent: "space-evenly" }}
      >
        <Button onClick={onConfirm} color="primary" variant="outlined">
          Confirm
        </Button>
        <Button
          onClick={() => onClose(false)}
          color="secondary"
          variant="outlined"
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmationDialog;
