import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import Cookies from "js-cookie";
import {
  TextField,
  Grid,
  Box,
  Button,
  Typography,
  IconButton,
} from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import url from "../../common/url.component";
import {
  CRACKED_CARD_CONSTANTS,
  FORM_TABLE_CONSTANTS,
  CURRENT_STATUS,
} from "./options";
import ConfirmationDialog from "../../common/ConfirmationDialog";

function CrackedScreenCard(props) {
  const { uuid } = useParams(false);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [updateData, setUpdateData] = useState({});
  const navigate = useNavigate();
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [brand, setBrand] = useState("");
  const [note, setNote] = useState([]);
  const [model, setModel] = useState("");
  const [buyPrice, setBuyPrice] = useState("");
  const [sellPrice, setSellPrice] = useState("");
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isEditable, setIsEditable] = useState(true);
  const [level, setLevel] = useState(0);
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (uuid) {
      fetch(url("/db/buyback/cracked-screen/" + uuid), {
        method: "GET",
        credentials: "include",
      })
        .then((res) => {
          if (res.status !== 200) {
            navigate("/buyback/status");
            return;
          }
          return res.json();
        })
        .then((res) => {
          if (res) {
            setData(res.data);
            setNote(res.data.note_list.join("\n"));
            setBrand(res.data.brand);
            setModel(res.data.model);
            setBuyPrice(res.data.buy_price);
            setSellPrice(res.data.sell_price || "");
            setSelectedStatus(res.data.status);
            setSelectedGrade(res.data.grade);
            setIsLoading(false);
          }
        })
        .catch((error) => console.error(error));
    }
  }, [uuid, navigate]);

  const handleConfirmUpdate = () => {
    setIsDialogOpen(false);
    setIsEditable(false);
    handleUpdate();
  };

  const handleGradeClick = (grade) => {
    setSelectedGrade(grade);
    setUpdateData({ ...updateData, grade: grade });
  };

  const getGradesForBrand = () => {
    if (brand && FORM_TABLE_CONSTANTS[brand]) {
      return FORM_TABLE_CONSTANTS[brand].grade;
    }
    return [];
  };

  const handleUpdate = () => {
    setIsEditable(false);

    const updatePayload = {
      ...data,
      status: selectedStatus,
      grade: selectedGrade,
      note_list: note,
    };
    // console.log(updatePayload);
    fetch(url("/db/buyback/cracked-screen/update-status/"), {
      method: "PUT",
      headers: { "X-CSRFToken": Cookies.get("csrftoken") },
      credentials: "include",
      body: JSON.stringify(updatePayload),
    })
      .then((res) => res.json())
      .then((res) => {
        setSelectedGrade(selectedGrade);
        setSelectedStatus(selectedStatus);
        navigate("/buyback/status");
      })
      .catch((error) => console.error(error));
  };

  const handleSensitiveUpdate = () => {
    const updatePayload = {
      ...data,
      // model: model,
      buy_price: buyPrice,
      sell_price: sellPrice,
    };
    fetch(url("/db/buyback/cracked-screen/update-sensitive-data/"), {
      method: "PUT",
      headers: { "X-CSRFToken": Cookies.get("csrftoken") },
      credentials: "include",
      body: JSON.stringify(updatePayload),
    })
      .then((res) => res.json())
      .then((res) => {
        setBuyPrice(buyPrice);
        setSellPrice(sellPrice || "");
        setMessage(res.message);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    fetch(url(`/db/admin/get-level/`), {
      method: "GET",
      credentials: "include",
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setLevel(res.level);
        // console.log(res.level);
      })
      .catch((error) => console.error(error));
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
          width: { xs: "100%", md: "50%" },
        }}
      >
        <Typography variant="h6">Cracked # {data.id}</Typography>
        <Box sx={{ display: "flex", gap: 1 }}>
          {data.label_url && (
            <IconButton
              href={data.label_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <DescriptionIcon />
            </IconButton>
          )}
          <Button
            size="small"
            variant="contained"
            component={Link}
            to={CRACKED_CARD_CONSTANTS.MAIN_PAGE}
          >
            BACK
          </Button>
          {/* {uuid && (
          <Button variant="contained" onClick={handleDelete}>
            DELETE
          </Button>
        )} */}
        </Box>
      </Box>
      <ConfirmationDialog
        state={{
          isOpen: isDialogOpen,
          onClose: setIsDialogOpen,
          onConfirm: handleConfirmUpdate,
        }}
      />
      <Grid
        container
        spacing={2}
        direction="column"
        sx={{ width: { xs: "100%", md: "50%" } }}
      >
        <Grid item xs={12} md={6}>
          {/* <InvoiceSubCard state={state} /> */}
          <Typography variant="h6">
            <b>Model: {model}</b>
          </Typography>
          <Typography variant="h6">Update Grade</Typography>
          <TextField
            value={selectedGrade}
            inputProps={{
              style: { color: "black" },
            }}
            variant="outlined"
            fullWidth
            placeholder="Selected Grade"
            onChange={(e) => setSelectedGrade(e.target.value)}
          />
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            {getGradesForBrand().map((grade) => (
              <Button
                key={grade}
                variant="contained"
                onClick={() => handleGradeClick(grade)}
                sx={{
                  backgroundColor: selectedGrade === grade ? "green" : "grey",
                  "&:hover": {
                    backgroundColor:
                      selectedGrade === grade ? "darkgreen" : "lightgrey",
                  },
                }}
              >
                {grade}
              </Button>
            ))}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          {/* <InvoiceSubCard state={state} /> */}
          <Typography variant="h6">Update Status</Typography>
          <TextField
            value={selectedStatus}
            inputProps={{
              style: { color: "black" },
            }}
            variant="outlined"
            fullWidth
            placeholder="Selected Status"
            onChange={(e) => setSelectedStatus(e.target.value)}
          />
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            {CURRENT_STATUS.map((name) => (
              <Button
                key={name}
                variant="contained"
                onClick={() => setSelectedStatus(name)}
                sx={{
                  backgroundColor: selectedStatus === name ? "green" : "grey",
                  "&:hover": {
                    backgroundColor:
                      selectedStatus === name ? "darkgreen" : "lightgrey",
                  },
                }}
              >
                {name}
              </Button>
            ))}
          </Box>{" "}
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            multiline
            rows={4}
            value={note}
            variant="outlined"
            fullWidth
            placeholder="For Admin Use Only"
            sx={{ fontSize: 32 }}
            onChange={(e) => setNote(e.target.value)}
          ></TextField>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <Button
            variant="contained"
            onClick={() => setIsDialogOpen(true)}
            disabled={!isEditable}
          >
            Update
          </Button>
        </Grid>

        {level >= 4 && (
          <React.Fragment>
            {/* <Grid item xs={12} md={6}>
            <Typography variant="h6">Update Model</Typography>
            <TextField
              value={model}
              variant="outlined"
              fullWidth
              placeholder="Enter Model Name"
              onChange={handleModelChange}
            />
          </Grid> */}
            <Grid item xs={12} md={6}>
              <Typography variant="h6">Update Buy Price</Typography>
              <TextField
                value={buyPrice}
                variant="outlined"
                fullWidth
                placeholder="Enter Buy Price"
                onChange={(e) => setBuyPrice(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6">Update Sell Price</Typography>
              <TextField
                value={sellPrice || ""}
                variant="outlined"
                fullWidth
                placeholder="Enter Sell Price"
                onChange={(e) => setSellPrice(e.target.value)}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <Button variant="contained" onClick={handleSensitiveUpdate}>
                Update Prices Only
              </Button>
              <Typography
                variant="body1"
                sx={{ color: "red", marginTop: "10px" }}
              >
                {message}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6">
                <b>Records</b>
              </Typography>
              {data.record_list
                .map((item, index) => (
                  <div key={index}>
                    <p>Time: {item.time}</p>
                    <p>Person: {item.person}</p>
                    <p>Grade: {item.grade}</p>
                    <p>Status: {item.status}</p>
                    <p>Note: {item.note}</p>
                    <hr />
                  </div>
                ))
                .reverse()}
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    </Box>
  );
}
export default CrackedScreenCard;
