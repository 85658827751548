import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import { ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";
import LogoutIcon from "@mui/icons-material/Logout";
import Cookies from "js-cookie";
import url from "../common/url.component";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { StyledSearchContainer, grayTheme } from "./options";
import { SearchIconWrapper, StyledInputBase } from "./options";
import { admin, others } from "./options";

const pages = ["Customers", "Invoices", "Tickets", "Inventory", "Screen"];
const submenu = {
  Customers: null,
  Invoices: null,
  Tickets: null,
  Inventory: ["Products", "Services", "Screens", "Material"],
  Screen: ["Buyback", "Insurance"],
};

function Navbar() {
  // const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  // const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const [state, setState] = useState(false);

  const [submenuAnchorEl, setSubMenuAnchorEl] = useState(null);
  const [submenuId, setSubMenuId] = useState(null);

  const handleMenuClick = (event, menuId) => {
    setSubMenuAnchorEl(event.currentTarget);
    setSubMenuId(menuId);
  };

  const handleMenuClose = () => {
    setSubMenuAnchorEl(null);
    setSubMenuId(null);
  };

  // const handleProfileMenuOpen = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  // const handleMenuClose = () => {
  //   setAnchorEl(null);
  //   handleMobileMenuClose();
  // };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = "primary-search-account-menu";
  // const renderMenu = (
  //   <Menu
  //     anchorEl={anchorEl}
  //     anchorOrigin={{
  //       vertical: "top",
  //       horizontal: "right",
  //     }}
  //     id={menuId}
  //     keepMounted
  //     transformOrigin={{
  //       vertical: "top",
  //       horizontal: "right",
  //     }}
  //     open={isMenuOpen}
  //     onClose={handleMenuClose}
  //   >
  //     <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
  //     <MenuItem onClick={handleMenuClose}>My account</MenuItem>
  //   </Menu>
  // );
  const handleLogout = (event) => {
    event.preventDefault();
    fetch(url(`/db/admin/logout/`), {
      method: "PUT",
      headers: { "X-CSRFToken": Cookies.get("csrftoken") },
      credentials: "include",
    })
      .then((res) => res.json())
      .then((res) => {
        Cookies.remove("isLoggedIn");
        Cookies.remove("csrftoken");
        window.location.href = "/login";
      })
      .catch((error) => console.error(error));
  };

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/* <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem> */}
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(open);
  };

  const list = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {admin.map((entry, index) => (
          <ListItem key={entry.name + index} disablePadding>
            <ListItemButton component={Link} to={entry.link}>
              <ListItemIcon>{entry.icon}</ListItemIcon>
              <ListItemText primary={entry.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {others.map((entry, index) => (
          <ListItem key={entry.name + index} disablePadding>
            <ListItemButton component={Link} to={entry.link}>
              <ListItemIcon>{entry.icon}</ListItemIcon>
              <ListItemText primary={entry.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  if (!Cookies.get("isLoggedIn")) {
    return null;
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <ThemeProvider theme={grayTheme}>
        <AppBar position="static">
          <Toolbar>
            <Button component={Link} to={"/"}>
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{
                  color: "white",
                  fontSize: "20px",
                  textTransform: "none",
                  display: { xs: "none", sm: "block" },
                }}
              >
                Lenwell
              </Typography>
            </Button>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {pages.map((page) => (
                <div key={page}>
                  <Button
                    // key={page}
                    // onClick={handleCloseNavMenu}
                    component={Link}
                    to={submenu[page] ? null : "/" + page}
                    sx={{
                      my: 2,
                      color: "white",
                      display: "block",
                      margin: "10px",
                      fontSize: "16px",
                      textTransform: "none",
                    }}
                    onClick={
                      submenu[page] ? (e) => handleMenuClick(e, page) : () => {}
                    }
                  >
                    {page}
                  </Button>
                  {submenuId === page && (
                    <Menu
                      anchorEl={submenuAnchorEl}
                      open={Boolean(submenuAnchorEl)}
                      onClose={handleMenuClose}
                    >
                      <div>
                        {submenu[page].map((menuId, index) => (
                          <MenuItem
                            key={menuId + index}
                            onClick={handleMenuClose}
                            component={Link}
                            to={"/" + menuId}
                          >
                            {menuId}
                          </MenuItem>
                        ))}
                      </div>
                    </Menu>
                  )}
                </div>
              ))}
            </Box>
            <StyledSearchContainer>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "search" }}
              />
            </StyledSearchContainer>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              {/* <IconButton
                size="large"
                aria-label="show 17 new notifications"
                color="inherit"
              >
                <Badge badgeContent={17} color="error">
                  <NotificationsIcon />
                </Badge>
              </IconButton> */}
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                // onClick={handleProfileMenuOpen}
                color="inherit"
                onClick={toggleDrawer(true)}
              >
                <AccountCircle />
              </IconButton>
              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleLogout}
                color="inherit"
              >
                <LogoutIcon />
              </IconButton>
            </Box>
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
      </ThemeProvider>
      {renderMobileMenu}
      {/* {renderMenu} */}
      <Drawer anchor="right" open={state} onClose={toggleDrawer(false)}>
        {list}
      </Drawer>
    </Box>
  );
}

export default Navbar;
