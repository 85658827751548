export const APPLICANT_CARD_CONSTANTS = {
  TITLE: "Applicant Detail",
  MAIN_PAGE: "/waitlist",
  UPDATE_ITEM: {
    link: "/db/waitlist/approve/",
  },
  IMAGE: {
    name: "Tax File Information",
    key: "tax_url",
    size: 800,
  },
  INPUTS: [
    {
      id: "0",
      name: "First Name",
      key: "first_name",
      // type: "text",
      placeholder: "First Name",
    },
    {
      id: "1",
      name: "Last Name",
      key: "last_name",
      // type: "text",
      placeholder: "Last Name",
    },
    {
      id: "2",
      name: "Email",
      key: "email",
      // type: "number",
      placeholder: "Email",
    },
    {
      id: "3",
      name: "Phone",
      key: "phone",
      // type: "number",
      placeholder: "Phone",
    },
    {
      id: "4",
      name: "Company",
      key: "company",
      // type: "text",
      placeholder: "Business Name",
    },
    {
      id: "5",
      name: "Tax ID",
      key: "tax_id",
      // type: "number",
      placeholder: "Tax ID",
    },
    {
      id: "6",
      name: "Address Line 1",
      key: "address1",
      // type: "number",
      placeholder: "Address Line 1",
    },
    {
      id: "7",
      name: "Address Line 2",
      key: "address2",
      // type: "number",
      placeholder: "Optional",
    },
    {
      id: "8",
      name: "City",
      key: "city",
      // type: "number",
      placeholder: "City",
    },
    {
      id: "9",
      name: "State",
      key: "state",
      // type: "number",
      placeholder: "State",
    },
    {
      id: "10",
      name: "Zipcode",
      key: "zipcode",
      // type: "number",
      placeholder: "Zipcode",
    },
  ],
};
