import React from 'react';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';

const TablePagination = ({ totalPages, currentPage, onPageChange }) => {
  const handlePageChange = (event, page) => {
    onPageChange(page);
  };

  return (
    <Pagination
      count={totalPages}
      page={currentPage}
      onChange={handlePageChange}
      renderItem={(item) => (
        <PaginationItem
          component="button"
          onClick={() => handlePageChange(null, item.page)}
          {...item}
        />
      )}
    />
  );
};

export default TablePagination;
