import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import url from "../../common/url.component";
import { PRODUCT_CARD_CONSTANTS } from "./options";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextFieldCard from "../../common/cardComponents/textFieldCard.component";
import DescriptionCard from "../../common/cardComponents/descriptionCard.component";
import ImageSingleCard from "../../common/cardComponents/imageSingleCard.component";
import ImageMultiCard from "../../common/cardComponents/imageMultiCard.component";
import Cookies from "js-cookie";
import ConfirmationDialog from "../../common/ConfirmationDialog";

function ProductCard(props) {
  const { uuid } = useParams(false);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const state = { uuid, data, options: PRODUCT_CARD_CONSTANTS };

  const handleDelete = () => {
    fetch(url(PRODUCT_CARD_CONSTANTS.DELETE_ITEM.link), {
      method: "DELETE",
      headers: { "X-CSRFToken": Cookies.get("csrftoken") },
      credentials: "include",
      body: JSON.stringify({ uuid: uuid }),
    })
      .then((res) => res.json())
      .then((res) => {
        // Redirect to the desired page
        navigate(PRODUCT_CARD_CONSTANTS.MAIN_PAGE);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    if (uuid) {
      fetch(url("/db/inventory/" + uuid), {
        method: "GET",
      })
        .then((res) => {
          if (res.status !== 200) {
            navigate("/products");
            return;
          }
          return res.json();
        })
        .then((res) => {
          // console.log(res.items);
          if (res) {
            setData(res.items);
            setIsLoading(false);
          }
        })
        .catch((error) => console.error(error));
    } else {
      setData(PRODUCT_CARD_CONSTANTS.FORMULA);
      setIsLoading(false);
    }
  }, [uuid, navigate]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <Box sx={{ display: "flex", gap: 1 }}>
          <Button
            variant="contained"
            component={Link}
            to={PRODUCT_CARD_CONSTANTS.MAIN_PAGE}
          >
            BACK
          </Button>
          {uuid && (
            <Button variant="contained" onClick={() => setIsDialogOpen(true)}>
              DELETE
            </Button>
          )}
        </Box>
      </Box>
      <ConfirmationDialog
        state={{
          isOpen: isDialogOpen,
          onClose: setIsDialogOpen,
          onConfirm: handleDelete,
        }}
      />
      <Grid container spacing={2}>
        <Grid item xs={6} md={6}>
          <TextFieldCard state={state} />
        </Grid>
        <Grid item xs={6} md={6}>
          {uuid && <DescriptionCard state={state} />}
        </Grid>
        <Grid item xs={6} md={6}>
          {uuid && <ImageMultiCard state={state} />}
        </Grid>
        <Grid item xs={6} md={6}>
          {uuid && <ImageSingleCard state={state} />}
        </Grid>
      </Grid>
    </Box>
  );
}

export default ProductCard;
