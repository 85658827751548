import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useParams, useNavigate, Link } from "react-router-dom";
import { CUSTOMER_CARD_CONSTANTS } from "./options";
import Grid from "@mui/material/Grid";
import url from "../common/url.component";
import TextFieldCard from "../common/cardComponents/textFieldCard.component";
import ImageSingleCard from "../common/cardComponents/imageSingleCard.component";
// import Cookies from "js-cookie";
import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";

function CustomerCard(props) {
  const { uuid } = useParams(false);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const state = {
    uuid,
    data,
    options: CUSTOMER_CARD_CONSTANTS,
  };

  // const handleDelete = () => {
  //   fetch(url(`/db/customers/delete/`), {
  //     method: "DELETE",
  //     headers: { "X-CSRFToken": Cookies.get("csrftoken") },
  //     credentials: "include",
  //     body: JSON.stringify({ customer_uuid: uuid }),
  //   })
  //     .then((res) => res.json())
  //     .then((res) => {
  //       // Redirect to the desired page
  //       navigate("/customers");
  //     })
  //     .catch((error) => console.error(error));
  // };

  useEffect(() => {
    if (uuid) {
      fetch(url("/db/customers/" + uuid), {
        method: "GET",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
      })
        .then((res) => {
          if (res.status !== 200) {
            navigate("/customers");
            return;
          }
          return res.json();
        })
        .then((res) => {
          // console.log(res.customers);
          if (res) {
            setData(res.customers);
            setIsLoading(false);
          }
        })
        .catch((error) => console.error(error));
    } else {
      setData(CUSTOMER_CARD_CONSTANTS.FORMULA);
      setIsLoading(false);
    }
  }, [uuid, navigate]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: uuid ? "flex-end" : "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        {!uuid && (
          <Typography variant="h3" style={{ color: "red", fontSize: "1.5rem" }}>
            Password will be the last 8 digits of the phone number. An email
            will be sent.
          </Typography>
        )}
        <Box sx={{ display: "flex", gap: 1 }}>
          <Button
            variant="contained"
            component={Link}
            to={CUSTOMER_CARD_CONSTANTS.MAIN_PAGE}
          >
            BACK
          </Button>
          {/* {uuid && (
            <Button variant="contained" onClick={handleDelete}>
              DELETE
            </Button>
          )} */}
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <TextFieldCard state={state} />
        </Grid>
        {uuid && (
          <Grid item xs={12} md={12}>
            <Card state={state}>
              <CardHeader
                title="Orders (Not Available)"
                style={{ backgroundColor: "#899499", color: "white" }}
              />
            </Card>
          </Grid>
        )}
        {uuid && (
          <Grid item xs={12} md={12}>
            <ImageSingleCard state={state} />
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default CustomerCard;
