export const GRADES = ["A", "B", "C", "D", "BL", "S", "NT", "BRK", "AM", "DOT"];

export const FORM_TABLE_CONSTANTS = {
  iPhone: {
    header: ["iPhone", "A", "B", "C", "S", "NT", "BRK", "AM", "TOTAL"],
    grade: ["A", "B", "C", "S", "NT", "BRK", "AM"],
    span: [1, 2, 2, 2, 2, 2, 2, 2, 1]
  },
  iPad: {
    header: ["iPad", "A", "B", "C", "BL", "BRK", "TOTAL"],
    grade: ["A", "B", "C", "BL", "BRK"],
    span: [1, 2, 2, 2, 2, 2, 1],
  },
  Samsung: {
    header: ["Samsung", "A", "B", "C", "D", "NT", "DOT", "BRK", "TOTAL"],
    grade: ["A", "B", "C", "D", "NT", "DOT", "BRK"],
    span: [1, 2, 2, 2, 2, 2, 2, 2, 1],
  },
  iWatch: {
    header: ["iWatch", "A", "B", "C", "BRK", "TOTAL"],
    grade: ["A", "B", "C", "BRK"],
    span: [1, 2, 2, 2, 2, 1],
  },
}

export const BUYBACK_FORM_CONSTANTS = {
  TITLE: "Buyback Forms",
  // FILE_UPLOAD: {
  //   link: "/db/invoice/import-data/",
  // },
  FILE_DOWNLOAD: {
    link: "/db/buyback/form/export/",
  },
  EDIT_ITEM: {
    link: "/buyback/history/",
  },
  TABLE: {
    header: ["#", "Customer", "Date", "QTY", "Total"],
    key: ["id", "customer_name", "created_at", "quantity", "charge"],
  },
};

export const FORM_CARD_CONSTANTS = {
  TITLE: "Form",
  MAIN_PAGE: "/buyback/history",
  // ADD_ITEM: {
  //   link: "/db/invoice/add/",
  // },
  // UPDATE_ITEM: {
  //   link: "/db/invoice/update/",
  // },
  UPDATE_NOTE: {
    link: "/db/buyback/form/update-note/",
  },
  // DELETE_ITEM: {
  //   link: "/db/invoice/delete/",
  // },
  TAB: {
    key: [0, 1],
    value: ["Purchase Order", "Internal"]
  },
}
