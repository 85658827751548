import React, { createRef, useState } from "react";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import url from "../url.component";
import Cookies from "js-cookie";

function TextFieldCard(props) {
  const { data, options, uuid } = props.state;
  const [message, setMessage] = useState("");
  const inputs = options.INPUTS;
  const inputRefs = inputs.map(() => createRef());
  const navigate = useNavigate();

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const updatedData = inputs.reduce((data, input, index) => {
      data[input.key] = inputRefs[index].current.value;
      return data;
    }, {});
    // console.log(updatedData);
    if (uuid) {
      fetch(url(options.UPDATE_ITEM.link), {
        method: "PUT",
        headers: { "X-CSRFToken": Cookies.get("csrftoken") },
        credentials: "include",
        body: JSON.stringify({
          ...updatedData,
          uuid: uuid,
          customer_uuid: uuid,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          // console.log(res.message)
          setMessage(res.message);
        })
        .catch((error) => console.error(error));
    } else {
      fetch(url(options.ADD_ITEM.link), {
        method: "POST",
        headers: { "X-CSRFToken": Cookies.get("csrftoken") },
        credentials: "include",
        body: JSON.stringify(updatedData),
      })
        .then((res) => {
          if (res.status === 200) {
            navigate(options.MAIN_PAGE);
            // return;
          }
          return res.json();
        })
        .then((res) => {
          if (res) {
            setMessage(res.message);
            if (res.refresh) {
              window.location.reload();
            }
          }
        })
        .catch((error) => console.error(error));
    }
  };

  return (
    <Card sx={{ flexGrow: 1 }}>
      <CardHeader
        title={options.TITLE}
        style={{ backgroundColor: "#899499", color: "white" }}
      />
      <CardContent>
        <Box
          component="form"
          noValidate
          onSubmit={handleFormSubmit}
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              flex: 1,
            }}
          >
            {inputs.slice(0, 6).map((input) => (
              <FormControl key={input.id + data.uuid} variant="standard">
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  sx={{ fontSize: 32 }}
                >
                  {input.name}
                </InputLabel>
                <TextField
                  // multiline
                  defaultValue={data[input.key]}
                  variant="outlined"
                  fullWidth
                  placeholder={input.placeholder}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ fontSize: 32, marginTop: 5 }}
                  inputRef={inputRefs[input.id]}
                  // type={input.type}
                ></TextField>
              </FormControl>
            ))}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              flex: 1,
            }}
          >
            {inputs.slice(6).map((input) => (
              <FormControl key={input.id + data.uuid} variant="standard">
                <InputLabel
                  shrink
                  htmlFor="bootstrap-input"
                  sx={{ fontSize: 32 }}
                >
                  {input.name}
                </InputLabel>
                <TextField
                  // multiline
                  defaultValue={data[input.key]}
                  variant="outlined"
                  placeholder={input.placeholder}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{ fontSize: 32, marginTop: 5 }}
                  inputRef={inputRefs[input.id]}
                  // type={input.type}
                ></TextField>
              </FormControl>
            ))}

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "auto",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <Typography
                variant="body1"
                sx={{ marginBottom: "5px", color: "red", fontSize: "20px" }}
              >
                {message}
              </Typography>
              <Button variant="contained" type="submit">
                UPDATE
              </Button>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default TextFieldCard;
