import React, { useState, useEffect } from "react";
import url from "../common/url.component";
import { Box, Button, Grid, Typography, Badge } from "@mui/material";
import { Link } from "react-router-dom";

const buybackMenuItems = {
  adjustment: "Price Adjustment",
  applications: "Wholesalers' Applications",
  input: "Form Input",
  history: "Form History",
  status: "Screen Status",
  shipping: "Shipping Order (China)",
};

const buttonStyle = {
  backgroundColor: "#EEEEEE",
  color: "black",
  fontSize: "1.5rem",
  width: "100%",
  minWidth: "300px",
  textTransform: "none",
  whiteSpace: "nowrap",
};

function BuybackMenu(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [pendingNum, setPendingNum] = useState(0);

  useEffect(() => {
    fetch(url(`/db/buyback/count-pending/`), {
      method: "GET",
      credentials: "include",
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        // console.log(res.pending_count);
        setPendingNum(res.pending_count);
        setIsLoading(false);
      })
      .catch((error) => console.error(error));
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Box>
      <Typography variant="h3" mb="10%">
        LCD Buyback
      </Typography>

      <Grid
        container
        spacing={4}
        justifyContent="center"
        alignItems="center"
        sx={{
          flexDirection: { xs: "column", md: "row" }, // Responsive direction
        }}
      >
        {Object.keys(buybackMenuItems).map((item, index) => (
          <Grid key={item + index} item md={4} xs={12}> 
            {item.endsWith("applications") ? (
              <Badge
                badgeContent={pendingNum}
                color="primary"
                sx={{ width: "100%", minWidth: "300px" }}
              >
                <Button
                  variant="contained"
                  style={buttonStyle}
                  component={Link}
                  to={"/buyback/" + item}
                >
                  {buybackMenuItems[item]}
                </Button>
              </Badge>
            ) : (
              <Button
                variant="contained"
                style={buttonStyle}
                component={Link}
                to={"/buyback/" + item}
              >
                {buybackMenuItems[item]}
              </Button>
            )}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
export default BuybackMenu;
