import React, { useState } from "react";
import url from "../common/url.component";
import {
  Box,
  Typography,
  Grid,
  TextField,
  IconButton,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Cookies from "js-cookie";
// import { useNavigate } from "react-router-dom";

const PRICE_LIST = ["Subtotal", "Shipping", "Tax", "Total"];

function LineItemView(props) {
  const { uuid, data } = props.state;
  const { setData } = props;
  const [paymentMethod, setPaymentMethod] = useState("");
  // const navigate = useNavigate();

  const handleMakePayment = () => {
    fetch(url(`/db/invoices/make-payment/`), {
      method: "POST",
      headers: { "X-CSRFToken": Cookies.get("csrftoken") },
      credentials: "include",
      body: JSON.stringify({ uuid: uuid, payment_method: paymentMethod }),
    })
      .then((res) => res.json())
      .then((res) => {
        window.location.reload();
      })
      .catch((error) => console.error(error));
  };

  const handlePaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  const handleDeleteItem = (index) => {
    fetch(url(`/db/invoices/delete-line-item/`), {
      method: "DELETE",
      headers: { "X-CSRFToken": Cookies.get("csrftoken") },
      credentials: "include",
      body: JSON.stringify({ uuid: uuid, index: index }),
    })
      .then((res) => res.json())
      .then((res) => {
        // Redirect to the desired page
        setData(res.data);
      })
      .catch((error) => console.error(error));
  };

  return (
    <Grid item>
      <Typography variant="h6">Current Items</Typography>
      <Grid item container spacing={1}>
        {/* {console.log(data)} */}
        {data.item_list.map((item, index) => (
          <Grid
            item
            key={item.product_name + index}
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <Box style={{ flex: 1, background: "#F9F9F9", color: "#3277B4" }}>
              {item.product_name}
            </Box>
            <TextField
              style={{ flex: 1.5 }}
              value={item.description}
              variant="outlined"
              size="small"
              disabled
            />
            <TextField
              value={item.quantity}
              variant="outlined"
              disabled
              size="small"
              style={{ flex: 1 }}
            />
            @
            <TextField
              value={"$" + item.price}
              variant="outlined"
              disabled
              size="small"
              style={{ flex: 1 }}
            />
            <TextField
              value="NoTax"
              variant="outlined"
              disabled
              size="small"
              style={{ flex: 1 }}
            />
            {!item.ticket_id && data.status === "New" && (
              <IconButton onClick={() => handleDeleteItem(index)}>
                <DeleteIcon />
              </IconButton>
            )}
          </Grid>
        ))}
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Grid
            item
            xs={6}
            container
            direction="column"
            // justifyContent="flex-end"
            alignItems="center"
          >
            {PRICE_LIST.map((item, index) => (
              <Grid
                key={item + index}
                item
                container
                direction="row"
                justifyContent="space-between"
              >
                <Grid item>
                  <Typography variant="body1">{item}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1">
                    ${parseFloat(data.charge).toFixed(2)}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
          {data.status === "New" && (
            <Grid
              item
              container
              xs={6}
              display="flex"
              direction="column"
              justifyContent="center"
              style={{ paddingLeft: "10%", paddingRight: "10%" }}
            >
              <FormControl variant="standard">
                <InputLabel>Payment Method</InputLabel>
                <Select
                  value={paymentMethod}
                  label="Payment Method"
                  size="small"
                  onChange={handlePaymentMethodChange}
                >
                  <MenuItem value={"Cash"}>Cash</MenuItem>
                  <MenuItem value={"Offline CC"}>Offline CC</MenuItem>
                  <MenuItem value={"Cash App"}>Cash App</MenuItem>
                  <MenuItem value={"Quick Pay"}>Quick Pay</MenuItem>
                  <MenuItem value={"Other"}>Other</MenuItem>
                </Select>
              </FormControl>
              <Button
                style={{ textTransform: "none", border: "1px solid blue" }}
                onClick={handleMakePayment}
                disabled={!paymentMethod}
              >
                Make Payment
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default LineItemView;
