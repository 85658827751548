export const BUYBACK_LIST_CONSTANTS = {
  TITLE: "Buyback Applications",
  // FILE_UPLOAD: {
  //   link: "/db/invoice/import-data/",
  // },
  // FILE_DOWNLOAD: {
  //   link: "/db/invoice/export-data/",
  // },
  EDIT_ITEM: {
    link: "/buyback/applications/",
  },
  TABLE: {
    header: ["#", "Customer", "Items", "Status", "Date", "Total"],
    key: ["id", "company", "subject", "status", "created_at", "charge"],
  },
};

export const BUYBACK_CARD_CONSTANTS = {
  // TITLE: "Application Detail",
  // MAIN_PAGE: "/buyback/",
  // CHANGE_STATUS: {
  //   link: "/db/invoice/publish/",
  // },
  // UPDATE_ITEM: {
  //   link: "/db/invoice/update/",
  // },
  UPDATE_NOTE: {
    link: "/db/buyback/update-note/",
  },
  // DELETE_ITEM: {
  //   link: "/db/invoice/delete/",
  // },
};
