export const CRACKED_SCREEN_CONSTANTS = {
  TITLE: "Cracked Screens",
  // FILE_UPLOAD: {
  //   link: "/db/invoice/import-data/",
  // },
  FILE_DOWNLOAD: {
    link: "/db/buyback/cracked-screen/export/",
  },
  EDIT_ITEM: {
    link: "/buyback/status/",
  },
  TABLE: {
    header: ["#", "Model", "Grade", "Status", "Date", "Last Update"],
    key: ["id", "model", "grade", "status", "created_at", "updated_at"],
  },
};

export const CRACKED_CARD_CONSTANTS = {
  // TITLE: "Invoice Detail",
  MAIN_PAGE: "/buyback/status",
  // ADD_ITEM: {
  //   link: "/db/invoice/add/",
  // },
  // UPDATE_ITEM: {
  //   link: "/db/invoice/update/",
  // },
  UPDATE_NOTE: {
    link: "/db/buyback/cracked-screen/update-note/",
  },
  // UPDATE_DESC: {
  //   link: "/db/invoices/update-notes/",
  // },
  // DELETE_ITEM: {
  //   link: "/db/invoice/delete/",
  // },
};

export const CURRENT_STATUS = ["Registered", "Under Repair", "Repaired", "Damaged", 
"Mailing", "Received", "Sold"]

export const FORM_TABLE_CONSTANTS = {
  iPhone: {
    grade: ["A", "B", "C", "S", "NT", "AM", "BRK",],
  },
  iPad: {
    grade: ["A", "B", "C", "BL", "BRK"],
  },
  Samsung: {
    grade: ["A", "B", "C", "D", "NT", "DOT", "BRK"],
  },
  iWatch: {
    grade: ["A", "B", "C", "BRK"],
  },
}



