import CustomerSelector from "../common/customer-selector";
import DeviceSelector from "./components/device-selector";
import TaskSelector from "./components/task-selector";
import { useState, useCallback } from "react";
import ProblemSelector from "./components/problem-selector";
import { Grid, Button, Box, Typography } from "@mui/material";
import Cookies from "js-cookie";
import url from "../common/url.component";
import { useNavigate } from "react-router-dom";

function TicketGenerator(props) {
  const [customer, setCustomer] = useState("");
  const [device, setDevice] = useState({});
  const [tasks, setTasks] = useState([]);
  const [problems, setProblems] = useState([]);
  const navigate = useNavigate();

  const handleCreateNewTicket = (event) => {
    console.log(customer);
    console.log(device);
    console.log(tasks);
    console.log(problems);
    fetch(url(`/db/tickets/add/`), {
      method: "POST",
      headers: { "X-CSRFToken": Cookies.get("csrftoken") },
      credentials: "include", // MUST
      body: JSON.stringify({
        uuid: customer,
        task_list: tasks,
        subject: `${device.model}${device.color ? " - " : ""}${device.color}${
          problems.length ? " - " : ""
        }${problems.join(" - ")}`,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        // console.log(res.message);
        navigate("/tickets");
      })
      .catch((error) => {
        console.error("Error occurred during status update:", error);
      });
  };

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        transform: "scale(0.9)", // scale the page
        transformOrigin: "top",
        // minHeight: '100vh',
      }}
    >
      <Typography variant="h6">New Ticket</Typography>
      <CustomerSelector
        createBtn={false}
        setCustomer={useCallback((customer) => {
          console.log(customer);
          setCustomer(customer);
        }, [])}
      />
      <DeviceSelector
        setDevice={useCallback((device) => {
          setDevice(device);
          console.log(device);
        }, [])}
      />
      <TaskSelector
        setTasks={useCallback((tasks) => {
          setTasks(tasks);
          console.log(tasks);
        }, [])}
      />
      <ProblemSelector
        setProblems={useCallback((problems) => {
          setProblems(problems);
          console.log(problems);
        }, [])}
      />
      <Grid item style={{ alignSelf: "flex-end" }}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          className="m-1"
          disabled={!device.model || !customer}
          onClick={async () => {
            await handleCreateNewTicket();
          }}
        >
          Create Ticket
        </Button>
      </Grid>
    </Box>
  );
}

export default TicketGenerator;
