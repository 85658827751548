import React from "react";
import url from "../common/url.component";
import {
  Box,
  Typography,
  Grid,
  TextField,
  IconButton,
  Button,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { UNLOCKED_STATUS } from "./options";

function LineItemView(props) {
  const { uuid, data } = props.state;
  const { setData } = props;
  const navigate = useNavigate();

  const handleMakeInvoice = () => {
    fetch(url(`/db/tickets/make-invoice/`), {
      method: "POST",
      headers: { "X-CSRFToken": Cookies.get("csrftoken") },
      credentials: "include",
      body: JSON.stringify({ uuid: uuid }),
    })
      .then((res) => res.json())
      .then((res) => {
        navigate("/invoices/" + res.message);
      })
      .catch((error) => console.error(error));
  };

  const handleDeleteItem = (index) => {
    fetch(url(`/db/tickets/delete-line-item/`), {
      method: "DELETE",
      headers: { "X-CSRFToken": Cookies.get("csrftoken") },
      credentials: "include",
      body: JSON.stringify({ uuid: uuid, index: index }),
    })
      .then((res) => res.json())
      .then((res) => {
        // Redirect to the desired page
        setData(res.data);
      })
      .catch((error) => console.error(error));
  };

  return (
    <Grid item>
      <Typography variant="h6">Current Items</Typography>
      <Grid item container spacing={1}>
        {data.item_list.map((item, index) => (
          <Grid
            item
            key={item.product_name + index}
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <Box style={{ flex: 1, background: "#F9F9F9", color: "#3277B4" }}>
              {item.product_name}
            </Box>
            <TextField
              style={{ flex: 1.5 }}
              value={item.description}
              variant="outlined"
              size="small"
              disabled
            />
            <TextField
              value={item.quantity}
              variant="outlined"
              disabled
              size="small"
              style={{ flex: 1 }}
            />
            @
            <TextField
              value={"$" + item.price}
              variant="outlined"
              disabled
              size="small"
              style={{ flex: 1 }}
            />
            <TextField
              value="NoTax"
              variant="outlined"
              disabled
              size="small"
              style={{ flex: 1 }}
            />
            {!item.ticket_id && UNLOCKED_STATUS.includes(data.status) && (
              <IconButton onClick={() => handleDeleteItem(index)}>
                <DeleteIcon />
              </IconButton>
            )}
          </Grid>
        ))}
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Grid item xs={6}>
            <Typography variant="h6">Estimated Total ${data.charge}</Typography>
          </Grid>
          {UNLOCKED_STATUS.includes(data.status) && (
            <Grid item xs={6} display="flex" justifyContent="center">
              <Button
                style={{ textTransform: "none", border: "1px solid blue" }}
                onClick={handleMakeInvoice}
              >
                Make Invoice
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default LineItemView;
