import React, { useEffect, useState } from "react";
import url from "../../common/url.component";
import AuthWrapper from "../../common/authWrapper.component";
import BaseList from "../../common/BaseList";
import { BUYBACK_LIST_CONSTANTS } from "./options";

function BuybackApplicationList(props) {
  const [isLoading, setIsLoading] = useState(true);
  const isLoading2 = AuthWrapper();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch(url(`/db/buyback/?page=${page}`), {
      method: "GET",
      credentials: "include",
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        // console.log(res.buyback);
        setData(res.buyback);
        setTotalPages(res.total_pages);
        setIsLoading(false);
      })
      .catch((error) => console.error(error));
  }, [page]);

  if (isLoading2 || isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <BaseList
      data={data}
      options={BUYBACK_LIST_CONSTANTS}
      hasTab={false}
      hasNewBtn={false}
      hasDownloadBtn={false}
      hasUploadBtn={false}
      hasSearchBar={true}
      hasTable={true}
      page={page}
      setPage={setPage}
      totalPages={totalPages}
      hasEnableBtn={false}
    />
  );
}
export default BuybackApplicationList;
