import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Button,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useParams, useNavigate, Link } from "react-router-dom";
import Cookies from "js-cookie";
import Note from "../../common/cardComponents/Note";
import url from "../../common/url.component";
import { BUYBACK_CARD_CONSTANTS } from "./options";

function BuybackApplicationCard(props) {
  const { uuid } = useParams(false);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [status, setStatus] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [itemList, setItemList] = useState([]);
  const state = {
    uuid,
    data,
    options: BUYBACK_CARD_CONSTANTS,
  };

  useEffect(() => {
    if (uuid) {
      fetch(url("/db/buyback/" + uuid), {
        method: "GET",
        credentials: "include",
      })
        .then((res) => {
          if (res.status !== 200) {
            navigate("/buyback/applications");
            return;
          }
          return res.json();
        })
        .then((res) => {
          if (res) {
            setData(res.application);
            setStatus(res.application.status);
            setPaymentMethod(res.application.payment_method);
            setItemList(
              res.application.item_list.map((item) => ({
                ...item,
                quantity: item.quantity,
                price: item.price,
              }))
            );
            setIsLoading(false);
          }
        })
        .catch((error) => console.error(error));
    }
  }, [uuid, navigate]);

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handlePaymentChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  const handleQuantityChange = (index, e) => {
    const newQuantity = Number(e.target.value);
    const newItemList = [...itemList];
    newItemList[index].quantity = newQuantity;
    setItemList(newItemList);
  };

  const handleQuantityBlur = (index) => {
    const newItemList = [...itemList];
    if (!newItemList[index].quantity) {
      newItemList[index].quantity = 0;
      setItemList(newItemList);
    }
  };

  const handlePriceChange = (index, e) => {
    const newPrice = Number(e.target.value);
    const newItemList = [...itemList];
    newItemList[index].price = newPrice;
    setItemList(newItemList);
  };

  const handlePriceBlur = (index) => {
    const newItemList = [...itemList];
    if (!newItemList[index].price) {
      newItemList[index].price = 0;
      setItemList(newItemList);
    }
  };

  const totalCharge = itemList.reduce((total, item) => {
    const itemTotal = (item.quantity || 0) * (item.price || 0);
    return total + itemTotal;
  }, 0);

  const handleSubmit = () => {
    const updatedData = {
      ...data,
      charge: totalCharge,
      status,
      payment_method: paymentMethod,
      item_list: itemList,
    };
    fetch(url(`/db/buyback/update-status/`), {
      method: "PUT",
      headers: { "X-CSRFToken": Cookies.get("csrftoken") },
      credentials: "include",
      body: JSON.stringify(updatedData),
    })
      .then((res) => res.json())
      .then((res) => {
        window.location.reload();
      })
      .catch((error) => console.error(error));
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Paper>
      <Grid container style={{ margin: "1%" }} spacing={"1%"}>
        <Grid item xs={12}>
          <Typography># {data.id}</Typography>
          <Typography>Company: {data.company}</Typography>
          <Typography component={Link} to={"/customers/" + data.customer_uuid}>
            Customer: {data.customer_name}
          </Typography>
          {/* <Typography>
              Address: {data.customer_address}
            </Typography> */}
          <Typography>Phone: {data.phone}</Typography>
          <Typography>Email: {data.email}</Typography>
        </Grid>
        <Grid item xs={12}>
          <TableContainer style={{ width: "95%" }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Product Name</TableCell>
                  <TableCell align="center">Quantity</TableCell>
                  <TableCell align="center">Price</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {itemList.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row" align="center">
                      {item.product}
                    </TableCell>
                    <TableCell align="center">
                      <TextField
                        type="number"
                        value={item.quantity}
                        onChange={(e) => handleQuantityChange(index, e)}
                        onBlur={() => handleQuantityBlur(index)}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <TextField
                        type="number"
                        value={item.price}
                        onChange={(e) => handlePriceChange(index, e)}
                        onBlur={() => handlePriceBlur(index)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={12} md={6}>
            <Typography variant="body1">Total Charge: {totalCharge}</Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              style={{ whiteSpace: "pre-line" }}
            >
              Comment: <br />
              {data.comment_list.length === 0
                ? "None"
                : data.comment_list.join("\n")}
            </Typography>
            <Box display="flex" alignItems="center">
              <Typography variant="body1" marginRight={2}>
                Payment Method:
              </Typography>
              <FormControl variant="standard">
                <Select
                  value={paymentMethod || data.payment_method}
                  onChange={handlePaymentChange}
                >
                  <MenuItem value="Store Credit">Store Credit</MenuItem>
                  <MenuItem value="Wire Transfer">Wire Transfer</MenuItem>
                  <MenuItem value="Zelle">Zelle</MenuItem>
                  <MenuItem value="Cash App">Cash App</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box display="flex" alignItems="center" marginBottom={1}>
              <Typography variant="body1" marginRight={2}>
                Status:
              </Typography>
              <FormControl variant="standard">
                <Select
                  value={status || data.status}
                  onChange={handleStatusChange}
                >
                  <MenuItem value="Pending">Pending</MenuItem>
                  <MenuItem value="Approved">Approved</MenuItem>
                  <MenuItem value="Denied">Denied</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              style={{ marginBottom: "2%" }}
            >
              Submit
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            {" "}
            <Note state={state} />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default BuybackApplicationCard;
