import { useEffect, useState } from "react";
import url from "./url.component";
import Cookies from "js-cookie";

function AuthWrapper(param = null) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!param) {
      fetch(url("/db/admin/status/"), {
        method: "GET",
        credentials: "include", // MUST
      }).then((res) => {
        if (res.status === 403) {
          Cookies.remove("isLoggedIn");
          Cookies.remove("csrftoken");
          window.location.href = "/login";
        } else if (!Cookies.get("isLoggedIn")) {
          Cookies.set("isLoggedIn", "true", { expires: 7, path: "/" });
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      });
    } else {
      setIsLoading(false);
    }
  }, [param]);

  if (isLoading) {
    return true;
  }

  return false;
}

export default AuthWrapper;
