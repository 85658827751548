import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useParams, useNavigate, Link } from "react-router-dom";
import { APPLICANT_CARD_CONSTANTS } from "./options";
import Grid from "@mui/material/Grid";
import url from "../common/url.component";
import TextFieldCard from "../common/cardComponents/textFieldCard.component";
import ImageSingleCard from "../common/cardComponents/imageSingleCard.component";
import Cookies from "js-cookie";

function Applicant(props) {
  const { uuid } = useParams(false);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const state = {
    uuid,
    data,
    options: APPLICANT_CARD_CONSTANTS,
  };

  const handleDelete = () => {
    fetch(url(`/db/waitlist/delete/`), {
      method: "DELETE",
      headers: { "X-CSRFToken": Cookies.get("csrftoken") },
      credentials: "include",
      body: JSON.stringify({ customer_uuid: uuid }),
    })
      .then((res) => res.json())
      .then((res) => {
        // Redirect to the desired page
        navigate("/waitlist");
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    if (uuid) {
      fetch(url("/db/waitlist/" + uuid), {
        method: "GET",
      })
        .then((res) => {
          if (res.status !== 200) {
            navigate("/waitlist");
            return;
          }
          return res.json();
        })
        .then((res) => {
          // console.log(res.waitlist);
          if (res) {
            setData(res.waitlist);
            setIsLoading(false);
          }
        })
        .catch((error) => console.error(error));
    }
  }, [uuid, navigate]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <Box sx={{ display: "flex", gap: 1 }}>
          <Button
            variant="contained"
            component={Link}
            to={APPLICANT_CARD_CONSTANTS.MAIN_PAGE}
          >
            BACK
          </Button>
          {uuid && (
            <Button variant="contained" onClick={handleDelete}>
              DELETE
            </Button>
          )}
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <TextFieldCard state={state} />
        </Grid>
        <Grid item xs={12} md={12}>
          <ImageSingleCard state={state} />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Applicant;
