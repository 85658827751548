import React from "react";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
// import { useNavigate } from "react-router-dom";
// import url from "../url.component";
// import Cookies from "js-cookie";

function ImageSingleCard(props) {
  const { data, options } = props.state;
  // const navigate = useNavigate();

  return (
    <Box sx={{ display: "flex", gap: "16px" }}>
      <Card sx={{ flexGrow: 1 }}>
        <CardHeader
          title={options.IMAGE.name}
          style={{ backgroundColor: "#899499", color: "white" }}
        />
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            flexGrow: 1,
            overflow: "auto",
          }}
        >
          {data[options.IMAGE.key].endsWith("pdf") ? (
            <iframe
              src={data[options.IMAGE.key]}
              title="PDF Viewer"
              style={{ width: "100%", height: "800px" }}
            ></iframe>
          ) : (
            <img
              src={data[options.IMAGE.key]}
              alt="Single Viewer"
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
            ></img>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "10px",
              alignSelf: "flex-end",
            }}
          >
            <Button variant="contained">Not Available</Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}

export default ImageSingleCard;
