import React, { useEffect, useState } from "react";
import {
  Typography,
  IconButton,
  TextField,
  Chip,
  Box,
  Grid,
  Stack,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import { StyledTicketPageBox } from "../../common/globalStyles.component";

export default function ProblemSelector({ setProblems }) {
  const [input, setInput] = useState([]);
  const [otherProblemInput, setOtherProblemInput] = useState("");

  useEffect(() => {
    setProblems(input);
  }, [input, setProblems]);

  const addProblem = (problem) => {
    setInput([...input, problem]);
  };

  const handleChipDelete = (index) => {
    const newData = [...input];
    newData.splice(index, 1);
    setInput(newData);
  };

  const AddProblemButton = ({ problemName }) => {
    return (
      <Grid item>
        <Box display="flex" alignItems="center" border="1px solid black">
          <Typography
            style={{
              backgroundColor: "black",
              color: "white",
              padding: "7px",
            }}
          >
            {problemName}
          </Typography>
          <IconButton
            onClick={() => {
              addProblem(problemName);
            }}
          >
            <Add />
          </IconButton>
        </Box>
      </Grid>
    );
  };

  const handleOtherProblem = () => {
    if (otherProblemInput.trim() !== "") {
      addProblem(otherProblemInput);
      setOtherProblemInput("");
    }
  };

  return (
    <Grid container spacing={"1%"}>
      <Grid item xs={12}>
        <Box style={{ display: "flex", width: "100%" }}>
          <StyledTicketPageBox>Problems</StyledTicketPageBox>
          <TextField
            variant="outlined"
            style={{ flex: 1 }}
            InputProps={{
              startAdornment: (
                <Stack direction="row" spacing={1}>
                  {input.map((problem, index) => (
                    <Chip
                      key={index + problem}
                      label={problem}
                      variant="outlined"
                      onDelete={() => handleChipDelete(index)}
                    />
                  ))}
                </Stack>
              ),
              readOnly: true,
            }}
          />
        </Box>
      </Grid>

      <Grid container item spacing={1}>
        <AddProblemButton problemName="Black Dot" />
        <AddProblemButton problemName="Shadow" />
        <AddProblemButton problemName="Touch Issue" />
        <AddProblemButton problemName="Backlight Issue" />
        <AddProblemButton problemName="Bent Frame" />
        <AddProblemButton problemName="No Display" />
        <AddProblemButton problemName="No Power" />
        <AddProblemButton problemName="Broken Screen" />
        <AddProblemButton problemName="Broken Backdoor" />
        <AddProblemButton problemName="Broken Camera Lens" />
        <AddProblemButton problemName="Broken Camera" />
        <AddProblemButton problemName="Broken Home Button" />
        <AddProblemButton problemName="LCD Only" />
        <Grid item>
          <Box border="1px solid black">
            <TextField
              size="small"
              label="Other"
              value={otherProblemInput}
              onChange={(e) => setOtherProblemInput(e.target.value)}
            />
            <IconButton
              onClick={(e) => {
                handleOtherProblem();
              }}
            >
              <Add />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
