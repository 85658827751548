import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Button,
  Typography,
  TextField,
  IconButton,
  Tab,
  Tabs,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Divider,
} from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import DeleteIcon from "@mui/icons-material/Delete";
import { Add } from "@mui/icons-material";
import { useParams, useNavigate, Link } from "react-router-dom";
import Cookies from "js-cookie";
import url from "../../common/url.component";
import { StyledTicketPageBox } from "../../common/globalStyles.component";
import { FORM_CARD_CONSTANTS, FORM_TABLE_CONSTANTS } from "./options";
import Note from "../../common/cardComponents/Note";

function BuybackFormCard(props) {
  const { uuid } = useParams(false);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = React.useState(1);
  const [brands, setBrands] = useState([]);
  const [brand, setBrand] = useState("");
  const [model, setModel] = useState("");
  const [otherGradeInput, setOtherGradeInput] = useState("");
  const [partialDict, setPartialDict] = useState({});
  const [itemList, setItemList] = useState([]);
  const [isGenerateButtonEnabled, setIsGenerateButtonEnabled] = useState(false);

  console.log(data);
  const state = {
    uuid,
    data,
    options: FORM_CARD_CONSTANTS,
  };

  const totalQuantity = itemList.reduce(
    (acc, item) => acc + (Number(item.quantity) || 0),
    0
  );

  const totalPrice = itemList.reduce(
    (acc, item) =>
      acc + (Number(item.price) || 0) * (Number(item.quantity) || 0),
    0
  );

  const calculatedTotalPrice =
    data && data.screen_list
      ? data.screen_list.reduce((acc, item) => acc + Number(item.total), 0)
      : 0;

  const calculatedTotalQuantity =
    data && data.screen_list
      ? data.screen_list.reduce((acc, item) => acc + Number(item.qty), 0)
      : 0;

  const isAddToListEnabled = itemList.every(
    (item) =>
      item.quantity &&
      !isNaN(Number(item.quantity)) &&
      Number(item.quantity) >= 1 &&
      item.price &&
      !isNaN(Number(item.price)) &&
      Number(item.price) >= 0
  );

  const isTotalMatched = () => {
    if (!brand || !model || !brands[brand] || !brands[brand][model]) {
      return false;
    }
    // to solve the bug about accuracy
    const brandModelPrice = parseFloat(brands[brand][model]["p"]).toFixed(2);
    const calculatedTotalPriceRounded = totalPrice.toFixed(2);

    const brandModelQuantity = parseInt(brands[brand][model]["q"], 10);
    const calculatedTotalQuantityRounded = Math.round(totalQuantity);

    return (
      brandModelPrice === calculatedTotalPriceRounded &&
      brandModelQuantity === calculatedTotalQuantityRounded
    );
  };

  const isAble = isTotalMatched() && isAddToListEnabled;

  useEffect(() => {
    if (uuid) {
      fetch(url("/db/buyback/form/" + uuid), {
        method: "GET",
        credentials: "include",
      })
        .then((res) => {
          if (res.status !== 200) {
            navigate("/buyback/history");
            return;
          }
          return res.json();
        })
        .then((res) => {
          if (res) {
            setData(res.form);
            setBrands(res.brands);
            setIsLoading(false);
          }
        })
        .catch((error) => console.error(error));
    }
  }, [uuid, navigate]);

  useEffect(() => {
    const expectedTotalQuantity = data?.quantity || 0;
    const currentTotalQuantity = calculatedTotalQuantity;
    const expectedTotalPrice = data?.charge.toFixed(2) || 0;
    const currentTotalPrice = calculatedTotalPrice.toFixed(2);

    const isQuantityMatch = expectedTotalQuantity === currentTotalQuantity;
    const isPriceMatch = expectedTotalPrice === currentTotalPrice;

    setIsGenerateButtonEnabled(isQuantityMatch && isPriceMatch);
  }, [data, calculatedTotalQuantity, calculatedTotalPrice]);

  const handleQuantityChange = (e, index) => {
    let newQuantity = e.target.value;
    if (newQuantity < 0) {
      newQuantity = 0;
    }
    const updatedItemList = itemList.map((item, i) => {
      if (i === index) {
        return { ...item, quantity: newQuantity };
      }
      return item;
    });
    setItemList(updatedItemList);
  };

  const handlePriceChange = (e, index) => {
    let newPrice = e.target.value;
    if (newPrice < 0) {
      newPrice = 0;
    }
    const updatedItemList = itemList.map((item, i) => {
      if (i === index) {
        return { ...item, price: newPrice };
      }
      return item;
    });
    setItemList(updatedItemList);
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleModelChange = (el) => {
    setModel(el);
    const modelInformation = data.item_list.find((item) => item.model === el);
    if (modelInformation) {
      const newData = {
        id: modelInformation.id,
        brand: modelInformation.brand,
        model: modelInformation.model,
        qty: modelInformation.qty,
        total: modelInformation.total,
        abbrev: modelInformation.abbrev,
      };
      console.log(newData);
      setPartialDict(newData);
    } else {
      console.log("Item not found for model:", el);
    }
    setItemList([]);
    console.log(el);
  };

  const handleOtherGrade = () => {
    if (otherGradeInput.trim() !== "") {
      itemList.push({
        grade: otherGradeInput.trim(),
        price: null,
        quantity: null,
      });
      console.log(otherGradeInput);
      setOtherGradeInput("");
    }
  };

  const handleAddItem = (el) => {
    const updatedItemList = [...itemList];
    updatedItemList.push({
      grade: el,
      price: null,
      quantity: null,
    });
    setItemList(updatedItemList);
  };

  const handleDeleteItem = (index) => {
    const updatedItemList = itemList.filter((item, i) => i !== index);
    setItemList(updatedItemList);
  };

  const handleAddModelToList = () => {
    const updatedModel = { ...partialDict, grade_information: itemList };

    console.log(updatedModel);
    fetch(url(`/db/buyback/form/add-model/`), {
      method: "PUT",
      headers: { "X-CSRFToken": Cookies.get("csrftoken") },
      credentials: "include",
      body: JSON.stringify({ form_uuid: uuid, updated_model: updatedModel }),
    })
      .then((res) => res.json())
      .then((res) => {
        setData(res.form);
        setItemList([]);
        setModel("");
        // window.location.reload();
      })
      .catch((error) => console.error(error));
  };

  const handleDeleteModelFromList = (index) => {
    fetch(url(`/db/buyback/form/remove-model/`), {
      method: "DELETE",
      headers: { "X-CSRFToken": Cookies.get("csrftoken") },
      credentials: "include",
      body: JSON.stringify({ form_uuid: uuid, index: index }),
    })
      .then((res) => res.json())
      .then((res) => {
        setData(res.form);
        // window.location.reload();
      })
      .catch((error) => console.error(error));
  };

  const handleCodeGenerate = () => {
    fetch(url(`/db/buyback/generate-batch-label/`), {
      method: "POST",
      headers: { "X-CSRFToken": Cookies.get("csrftoken") },
      credentials: "include",
      body: JSON.stringify({ form_uuid: uuid }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res.form.form_url);
        setData(res.form);
        // window.location.reload();
      })
      .catch((error) => console.error(error));
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <Typography variant="h3">
          {FORM_CARD_CONSTANTS.TITLE} # {data.id}
        </Typography>
        <Tabs value={tabIndex} onChange={handleTabChange}>
          {FORM_CARD_CONSTANTS.TAB.key.map((item, index) => (
            <Tab
              key={index + item}
              value={item}
              label={FORM_CARD_CONSTANTS.TAB.value[item]}
            />
          ))}
        </Tabs>
        <Box sx={{ display: "flex", gap: 1 }}>
          {data.form_url && (
            <IconButton
              href={data.form_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <DescriptionIcon />
            </IconButton>
          )}
          <Button
            variant="contained"
            component={Link}
            to={FORM_CARD_CONSTANTS.MAIN_PAGE}
          >
            BACK
          </Button>
          {/* {uuid && (
              <Button variant="contained" onClick={handleDelete}>
                DELETE
              </Button>
            )} */}
        </Box>
      </Box>
      <Paper>
        {tabIndex === 0 ? (
          data.item_list.map((item) => (
            <div key={item.id}>
              {/* Render each property of the item */}
              <div>{JSON.stringify(item, null, 2)}</div>
              {/* ... other properties */}
            </div>
          ))
        ) : (
          <div>
            {/* {!data.form_url && (
              <Button onClick={handleCodeGenerate}>Generate Batch Label</Button>
            )} */}
            <Grid container spacing={"1%"} paddingBottom={"1%"}>
              <Grid item xs={12}>
                <Box style={{ display: "flex", width: "100%" }}>
                  <StyledTicketPageBox marginRight={"2%"}>
                    Brand
                  </StyledTicketPageBox>
                  <Grid container item spacing={1}>
                    {Object.keys(brands).map((el, index) => {
                      return (
                        <Grid item key={`type-${index}`}>
                          <Button
                            className="m-1"
                            variant="contained"
                            style={{
                              backgroundColor: "green",
                              color: "white",
                              textTransform: "none",
                              marginRight: "8px",
                            }}
                            onClick={() => {
                              setBrand(el);
                              setModel("");
                              console.log(el);
                            }}
                          >
                            {el}
                          </Button>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
              </Grid>
              {brands[brand] && (
                <Grid item xs={12}>
                  <Box style={{ display: "flex", width: "100%" }}>
                    <StyledTicketPageBox marginRight={"2%"}>
                      Model
                    </StyledTicketPageBox>
                    <Grid container item spacing={1}>
                      {Object.keys(brands[brand]).map((el, index) => {
                        const isForbidden = data.screen_list.some(
                          (item) => item.model === el
                        );
                        return (
                          <Grid item key={`type-${index}`}>
                            <Button
                              className="m-1"
                              variant="contained"
                              style={{
                                backgroundColor: isForbidden ? "grey" : "green",
                                color: "white",
                                textTransform: "none",
                                marginRight: "8px",
                              }}
                              disabled={isForbidden}
                              onClick={() => {
                                handleModelChange(el);
                              }}
                            >
                              {el}
                            </Button>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Box>
                </Grid>
              )}
              {model && (
                <React.Fragment>
                  <Grid item xs={12}>
                    <Box style={{ display: "flex", width: "100%" }}>
                      <StyledTicketPageBox marginRight={"2%"}>
                        Grade
                      </StyledTicketPageBox>
                      <Grid container item spacing={1}>
                        {FORM_TABLE_CONSTANTS[brand].grade.map((el, index) => {
                          return (
                            <Grid item key={`type-${index}`}>
                              <Button
                                className="m-1"
                                variant="contained"
                                style={{
                                  backgroundColor: "green",
                                  color: "white",
                                  textTransform: "none",
                                  marginRight: "8px",
                                }}
                                onClick={() => handleAddItem(el)}
                              >
                                {el}
                              </Button>
                            </Grid>
                          );
                        })}
                        <Grid item>
                          <Box border="1px solid black">
                            <TextField
                              size="small"
                              label="Other"
                              value={otherGradeInput}
                              onChange={(e) =>
                                setOtherGradeInput(e.target.value)
                              }
                            />
                            <IconButton onClick={handleOtherGrade}>
                              <Add />
                            </IconButton>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid container item xs={12} spacing={2}>
                    <Grid item xs={6}>
                      {/* Left Content */}
                      <div>Selected: {model}</div>
                      {itemList.length > 0 && (
                        <React.Fragment>
                          <Table>
                            <TableHead>
                              <TableRow style={{ border: "none" }}>
                                <TableCell style={{ border: "none" }}>
                                  Grade
                                </TableCell>
                                <TableCell style={{ border: "none" }}>
                                  Quantity
                                </TableCell>
                                <TableCell style={{ border: "none" }}>
                                  Unit Price
                                </TableCell>
                                <TableCell style={{ border: "none" }}>
                                  Total Price
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {itemList.map((item, index) => (
                                <TableRow
                                  key={item.grade + index}
                                  style={{ border: "none" }}
                                >
                                  <TableCell style={{ border: "none" }}>
                                    {item.grade}
                                  </TableCell>
                                  <TableCell style={{ border: "none" }}>
                                    <TextField
                                      type="number"
                                      value={item.quantity || ""}
                                      onChange={(e) =>
                                        handleQuantityChange(e, index)
                                      }
                                      variant="outlined"
                                      size="small"
                                      style={{ border: "none" }}
                                      inputProps={{ min: 1 }}
                                    />
                                  </TableCell>
                                  <TableCell style={{ border: "none" }}>
                                    <TextField
                                      type="number"
                                      value={item.price || ""}
                                      onChange={(e) =>
                                        handlePriceChange(e, index)
                                      }
                                      variant="outlined"
                                      size="small"
                                      style={{ border: "none" }}
                                      inputProps={{ min: 0 }}
                                    />
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      border: "none",
                                      textAlign: "center",
                                    }}
                                  >
                                    $ {item.quantity * item.price}
                                  </TableCell>
                                  <TableCell style={{ border: "none" }}>
                                    <IconButton
                                      onClick={() => handleDeleteItem(index)}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                          <Grid
                            item
                            // xs={12}
                            // md={6}
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "flex-end",
                            }}
                          >
                            <Button
                              variant="contained"
                              size="small"
                              onClick={handleAddModelToList}
                              disabled={!isAble}
                              style={{
                                backgroundColor: isAble ? "blue" : "grey",
                              }}
                            >
                              Add to List
                            </Button>
                          </Grid>
                        </React.Fragment>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {/* Right Content */}
                      <div>
                        Expected Quantity = {brands[brand][model]["q"]} <br />
                        Expected Price = ${brands[brand][model]["p"]}
                      </div>
                    </Grid>
                  </Grid>
                </React.Fragment>
              )}
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">
                  Expected Total Quantity:{data.quantity}, Expected Total Price:
                  ${data.charge.toFixed(2)}
                  {/* {curTotalQuantity} */}
                </Typography>
                <Typography variant="h6">
                  Total Quantity:{calculatedTotalQuantity}, Total Price: $
                  {calculatedTotalPrice.toFixed(2)}
                  {/* {curTotalQuantity} */}
                </Typography>
                {data.screen_list.map((item) => (
                  <div
                    key={item.id}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    {/* Render each property of the item */}
                    <div style={{ marginRight: "10px" }}>
                      {JSON.stringify(item, null, 2)}
                    </div>
                    {/* ... other properties */}
                    <IconButton
                      onClick={() => handleDeleteModelFromList(item.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                ))}
                {!data.form_url && (
                  <Button
                    onClick={handleCodeGenerate}
                    disabled={!isGenerateButtonEnabled}
                    style={{
                      backgroundColor: isGenerateButtonEnabled
                        ? "blue"
                        : "grey",
                      color: "white",
                    }}
                  >
                    Generate Batch Label
                  </Button>
                )}
              </Grid>
            </Grid>
          </div>
        )}
      </Paper>
      <Note state={state} />
    </Box>
  );
}

export default BuybackFormCard;
