export const TICKET_LIST_CONSTANTS = {
  TITLE: "Tickets",
  ADD_ITEM: {
    name: "New Ticket",
    link: "/tickets/add",
  },
  EDIT_ITEM: {
    link: "/tickets/",
  },
  FILE_UPLOAD: {
    link: "/db/ticket/import-data/",
  },
  FILE_DOWNLOAD: {
    link: "/db/ticket/export-data/",
  },
  TABLE: {
    header: ["#", "Customer", "Subject", "Status", "Created", "Tasks", "Charge"],
    key: ["id", "company", "subject", "status", "created_at", "task_list", "charge"],
  },
  TAB: {
    key: [0, 1, 2],
    value: ["all", "wholesale", "retail"]
  },
};

export const UNLOCKED_STATUS = ["In Progress", "Ready to Pick up", "Please Call Us"];

export const TICKET_CARD_CONSTANTS = {
  TITLE: "Ticket Detail",
  MAIN_PAGE: "/tickets",
  ADD_ITEM: {
    link: "/db/tickets/add/",
  },
  UPDATE_ITEM: {
    link: "/db/tickets/update/",
  },
  UPDATE_DESC: {
    link: "/db/tickets/update-notes/",
  },
  DELETE_ITEM: {
    link: "/db/tickets/delete/",
  },
  IMAGE: {
    name: "Barcode / QR Code",
    key: "barcode_url",
    size: 400,
  },
  INPUTS: [
    {
      id: "0",
      name: "Subject",
      key: "subject",
      // type: "text",
      placeholder: "Subject",
    },
    {
      id: "1",
      name: "Status",
      key: "status",
      // type: "text",
      placeholder: "Status",
    },
    {
      id: "2",
      name: "Issue",
      key: "issue",
      // type: "text",
      placeholder: "Issue",
    },
    // {
    //   id: "3",
    //   name: "Customer",
    //   key: "customer_company",
    // },
    // {
    //   id: "4",
    //   name: "Invoiced",
    //   key: "created_at",
    //   // type: "number",
    //   placeholder: "Date",
    // },
  ],
};
