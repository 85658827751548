import { TextField, Box } from "@mui/material";
import { StyledTicketPageBox } from "./globalStyles.component";
import { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import url from "./url.component";
import Cookies from "js-cookie";

const MIN_SEARCH_LENGTH = 3;
const MAX_OPTIONS = 20;

export default function InventorySelector(props) {
  const [options, setOptions] = useState([]);
  const { setInventoryUUID, setSelected } = props;
  const { uuid, inventoryName, setInventoryName } = props.state;
  const [inventoryMap, setInventoryMap] = useState({});

  const handleTextFieldChange = (event) => {
    setInventoryUUID(null);
    setInventoryName(event.target.value);
    setSelected(false);
  };

  const handleAutocompleteChange = (event, inventoryName) => {
    setInventoryUUID(inventoryMap[inventoryName] || null);
    setInventoryName(inventoryName);
    setSelected(!!inventoryName);
  };

  const handleCodeScan = (event) => {
    console.log(event.target.value);
    fetch(url(`/db/invoices/scan-code/`), {
      method: "PUT",
      headers: { "X-CSRFToken": Cookies.get("csrftoken") },
      credentials: "include",
      body: JSON.stringify({
        invoice_uuid: uuid,
        inventory_uuid: event.target.value, // event.target.value is the same as inventoryUUID
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res.message);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    // Function to fetch inventory name suggestions from Django backend
    async function fetchInventorySuggestions() {
      try {
        if (inventoryName.length >= MIN_SEARCH_LENGTH) {
          const response = await fetch(
            url(`/db/inventory/search/?query=${inventoryName}`)
          );
          const data = await response.json();
          setInventoryMap(data.inventory);
          setOptions(Object.keys(data.inventory).slice(0, MAX_OPTIONS));
        } else {
          setOptions([]);
        }
      } catch (error) {
        console.error("Error fetching inventory suggestions:", error);
      }
    }

    // Fetch inventory suggestions when productName changes
    if (inventoryName) {
      fetchInventorySuggestions();
    } else {
      setOptions([]); // Clear the suggestions when input is empty
    }
  }, [inventoryName]);

  return (
    <Box style={{ display: "flex", width: "100%", paddingBottom: "1%" }}>
      <StyledTicketPageBox>Product</StyledTicketPageBox>
      <Autocomplete
        id="inventory-autocomplete"
        freeSolo
        options={options}
        getOptionLabel={(option) => option}
        style={{ flex: 1 }}
        value={inventoryName}
        onChange={handleAutocompleteChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label="At least 3 characters ..."
            variant="outlined"
            onChange={handleTextFieldChange}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleCodeScan(event);
                console.log("Enter key pressed");
              }
            }}
          />
        )}
      />
    </Box>
  );
}
