import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import url from "../common/url.component";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

function LinkedTickets(props) {
  const [ticketData, setTicketData] = useState([]);
  const { data, uuid } = props.state;
  const { setData } = props;
  const [ticketStatus, setTicketStatus] = useState({});
  const [updated, setUpdated] = useState(false); // update the linked tickets

  const handleAddTicket = (event, ticket_uuid) => {
    const updatedStatus = { ...ticketStatus };
    updatedStatus[ticket_uuid] = !updatedStatus[ticket_uuid];
    setTicketStatus(updatedStatus);
    fetch(url(`/db/invoices/bind-ticket/`), {
      method: "PUT",
      headers: { "X-CSRFToken": Cookies.get("csrftoken") },
      credentials: "include",
      body: JSON.stringify({
        ticket_uuid: ticket_uuid,
        uuid: uuid, // invoice_uuid
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        //   setMessage(res.message);
        setData(res.data);
        setUpdated(true);
      })
      .catch((error) => console.error(error));
  };

  const handleDeleteTicket = (event, ticket_uuid) => {
    const updatedStatus = { ...ticketStatus };
    updatedStatus[ticket_uuid] = !updatedStatus[ticket_uuid];
    setTicketStatus(updatedStatus);
    fetch(url(`/db/invoices/unbind-ticket/`), {
      method: "PUT",
      headers: { "X-CSRFToken": Cookies.get("csrftoken") },
      credentials: "include",
      body: JSON.stringify({
        ticket_uuid: ticket_uuid,
        uuid: uuid, // invoice_uuid
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        //   setMessage(res.message);
        setData(res.data);
        setUpdated(true);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    fetch(
      url(
        `/db/tickets/search-tickets/?uuid=${uuid}&customer_uuid=${data.customer_uuid}`
      ),
      {
        method: "GET",
        credentials: "include",
      }
    )
      .then((res) => res.json())
      .then((res) => {
        // console.log(res.employee);
        setTicketData(res.data);
        setTicketStatus(
          res.data.reduce((statusObj, item) => {
            statusObj[item.uuid] = item.invoice_id;
            return statusObj;
          }, {})
        );
        setUpdated(false);
      })
      .catch((error) => console.error(error));
  }, [uuid, data.customer_uuid, data.tickets, updated]);

  return (
    <Grid
      item
      container
      direction="column"
      xs={12}
      style={{ margin: "10px", background: "#EEEEEE" }}
    >
      <Grid
        item
        container
        direction="row"
        alignItems="center"
        spacing={1}
        mb={"1%"}
      >
        <Grid item>
          <Typography>Pending Ticket Charges</Typography>
        </Grid>
        <Grid item>
          <Chip
            label={ticketData.length}
            variant="filled"
            style={{ backgroundColor: "red", color: "white" }}
            size="small"
          />
        </Grid>

        {ticketData.map((item, index) => (
          <Grid
            item
            key={item.subject + index}
            style={{
              display: "flex",
              // alignItems: "center",
              // justifyContent: 'flex-start' ,
              flexDirection: "row",
            }}
            xs={12}
          >
            <Box
              style={{ flex: 0.5, textDecoration: "none" }}
              component={Link}
              to={"/tickets/" + item.uuid}
            >
              {"# " + item.id}
            </Box>
            <Box style={{ flex: 0.5 }}>{item.status}</Box>
            <Box style={{ flex: 1 }}>{item.subject}</Box>
            <Box style={{ flex: 1 }}>{"$" + item.charge}</Box>
            <IconButton
              disabled={!!ticketStatus[item.uuid]}
              onClick={(event) => handleAddTicket(event, item.uuid)}
            >
              <AddIcon />
            </IconButton>
            <IconButton
              disabled={!ticketStatus[item.uuid]}
              onClick={(event) => handleDeleteTicket(event, item.uuid)}
            >
              <RemoveIcon />
            </IconButton>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}

export default LinkedTickets;
