import React, { useState } from "react";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

function ImageMultiCard(props) {
  const { data } = props.state;
  const [images, setImages] = useState(data.product_url_list);
  // const navigate = useNavigate();
  const deleteImage = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  const addImage = () => {
    const newImage = "https://i.postimg.cc/8ztqh2Gb/repair.jpg";
    setImages([...images, newImage]);
  };

  return (
    <Box sx={{ display: "flex", gap: "16px" }}>
      <Card sx={{ flexGrow: 1 }}>
        <CardHeader
          title="Product Images"
          style={{ backgroundColor: "#899499", color: "white" }}
        />
        <CardContent>
          <ImageList>
            {images.map((image, index) => (
              <ImageListItem key={index}>
                <img src={image} alt={`Product ${index + 1}`} />
                {images.length > 1 && (
                  <IconButton onClick={() => deleteImage(index)}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </ImageListItem>
            ))}
            <ImageListItem>
              <IconButton onClick={addImage}>
                <AddIcon />
              </IconButton>
            </ImageListItem>
          </ImageList>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "auto",
            }}
          >
            <Button variant="contained">Not Available</Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}

export default ImageMultiCard;
