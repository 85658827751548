import { useEffect, useState } from "react";
import devices from "../devices";
import { TextField, Box, Button, Grid } from "@mui/material";
import { StyledTicketPageBox } from "../../common/globalStyles.component";

export default function DeviceSelector({ setDevice }) {
  const [type, setType] = useState("");
  const [typeFilter, setTypeFilter] = useState("");
  const [model, setModel] = useState("");
  const [modelFilter, setModelFilter] = useState("");
  const [color, setColor] = useState("");
  const [colorFilter, setColorFilter] = useState("");
  const [options, setOptions] = useState({});

  useEffect(() => {
    setDevice({ type, model, color, options });
  }, [type, model, color, options, setDevice]);

  return (
    <Grid container spacing={"1%"} paddingBottom={"1%"}>
      <Grid item xs={12}>
        <Box style={{ display: "flex", width: "100%" }}>
          <StyledTicketPageBox>Type</StyledTicketPageBox>
          <TextField
            onChange={(e) => {
              setType(e.target.value);
              setTypeFilter(e.target.value);
            }}
            variant="outlined"
            value={type}
            style={{ flex: 1 }}
          />
        </Box>
      </Grid>
      <Grid container item spacing={1}>
        {Object.keys(devices.models)
          .filter(
            (el) =>
              !typeFilter | el.toLowerCase().includes(typeFilter.toLowerCase())
          )
          .map((el, index) => {
            const isSelected = type === el;
            return (
              <Grid item key={`type-${index}`}>
                <Button
                  className="m-1"
                  variant="contained"
                  style={{
                    backgroundColor: type
                      ? isSelected
                        ? "green"
                        : "gray"
                      : "green",
                    color: "white",
                    textTransform: "none",
                    marginRight: "8px",
                  }}
                  onClick={() => {
                    setType(el);
                    setModelFilter("");
                  }}
                >
                  {el}
                </Button>
              </Grid>
            );
          })}
      </Grid>
      <Grid item xs={12}>
        <Box style={{ display: "flex", width: "100%" }}>
          <StyledTicketPageBox>Model</StyledTicketPageBox>
          <TextField
            value={model}
            onChange={(e) => {
              setModel(e.target.value);
              setModelFilter(e.target.value);
            }}
            style={{ flex: 1 }}
          />
        </Box>
      </Grid>
      <Grid container item spacing={1}>
        {devices.models[type]
          ?.filter(
            (el) =>
              !modelFilter |
              el[0].toLowerCase().includes(modelFilter.toLowerCase())
          )
          ?.map((el, index) => {
            const isSelected = model === el[1];
            return (
              <Grid item key={`model-${index}`}>
                <Button
                  className="m-1"
                  style={{
                    backgroundColor: model
                      ? isSelected
                        ? "green"
                        : "gray"
                      : "green",
                    color: "white",
                    textTransform: "none",
                  }}
                  onClick={() => {
                    setModel(el[1]);
                    setOptions(el[2]);
                  }}
                >
                  {el[0]}
                </Button>
              </Grid>
            );
          })}
      </Grid>
      <Grid item xs={12}>
        <Box style={{ display: "flex", width: "100%" }}>
          <StyledTicketPageBox>Color</StyledTicketPageBox>
          <TextField
            value={color}
            onChange={(e) => {
              setColor(e.target.value);
              setColorFilter(e.target.value);
            }}
            style={{ flex: 1 }}
          />
        </Box>
      </Grid>
      <Grid container item spacing={1}>
        {devices.colors
          .filter(
            (el) =>
              !colorFilter |
              el.toLowerCase().includes(colorFilter.toLowerCase())
          )
          .map((el, index) => {
            const isSelected = color === el;
            return (
              <Grid item key={`color-${index}`}>
                <Button
                  className="m-1"
                  style={{
                    backgroundColor: color
                      ? isSelected
                        ? "green"
                        : "gray"
                      : "green",
                    color: "white",
                    textTransform: "none",
                  }}
                  onClick={() => {
                    setColor(el);
                    setColorFilter("");
                  }}
                >
                  {el}
                </Button>
              </Grid>
            );
          })}{" "}
      </Grid>
    </Grid>
  );
}
