import React, { useEffect, useState } from "react";
import url from "../common/url.component";
import AuthWrapper from "../common/authWrapper.component";
import BaseList from "../common/BaseList";
import { TICKET_LIST_CONSTANTS } from "./options";

function TicketList(props) {
  const [isLoading, setIsLoading] = useState(true);
  const isLoading2 = AuthWrapper();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [data, setData] = useState(null);
  const [tab, setTab] = useState("All");

  useEffect(() => {
    fetch(url(`/db/tickets/?page=${page}&type=${tab}`), {
      method: "GET",
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        // console.log(res.tickets);
        setData(res.tickets);
        setTotalPages(res.total_pages);
        setIsLoading(false);
      })
      .catch((error) => console.error(error));
  }, [page, tab]);

  if (isLoading2 || isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <BaseList
      data={data}
      options={TICKET_LIST_CONSTANTS}
      hasTab={true}
      setTab={setTab}
      hasNewBtn={true}
      hasDownloadBtn={false}
      hasUploadBtn={false}
      hasSearchBar={true}
      hasTable={true}
      page={page}
      setPage={setPage}
      totalPages={totalPages}
      hasEnableBtn={false}
    />
  );
}
export default TicketList;
