import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";
import { PERMISSION_CARD_CONSTANTS } from "./options";
import Grid from "@mui/material/Grid";
import url from "../common/url.component";
import TextFieldCard from "../common/cardComponents/textFieldCard.component";
// import Cookies from "js-cookie";
import { StyledTableCell } from "../common/globalStyles.component";
import { StyledTableRow } from "../common/globalStyles.component";
import { StyledIconButton } from "../common/globalStyles.component";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import MenuItem from "@mui/material/MenuItem";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";

function PermissionCard(props) {
  const { uuid } = useParams(false);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [level, setLevel] = useState(0);
  // const navigate = useNavigate();
  const [selectedNumber, setSelectedNumber] = useState("");

  const handleNumberChange = (event) => {
    setSelectedNumber(event.target.value);
  };

  const state = {
    uuid,
    data,
    options: PERMISSION_CARD_CONSTANTS,
  };

  // const handleAdminDelete = () => {
  //   fetch(url(`/db/customers/delete/`), {
  //     method: "DELETE",
  //     headers: { "X-CSRFToken": Cookies.get("csrftoken") },
  //     credentials: "include",
  //     body: JSON.stringify({ customer_uuid: uuid }),
  //   })
  //     .then((res) => res.json())
  //     .then((res) => {
  //       // Redirect to the desired page
  //       navigate("/customers");
  //     })
  //     .catch((error) => console.error(error));
  // };

  useEffect(() => {
    fetch(url("/db/admin/all/"), {
      method: "GET",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((res) => {
        // console.log(res.employee);
        setData(res.employee);
        setLevel(res.level);
        setIsLoading(false);
      })
      .catch((error) => console.error(error));
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    level >= 4 && (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={12}>
            <Table aria-label="custom pagination table">
              <TableHead>
                <TableRow>
                  {PERMISSION_CARD_CONSTANTS.TABLE.header.map((item, index) => (
                    <StyledTableCell key={index + item}>{item}</StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row) => (
                  <StyledTableRow key={row.uuid}>
                    {PERMISSION_CARD_CONSTANTS.TABLE.key.map((item, index) => (
                      <StyledTableCell key={index + item}>
                        {row[item]}
                      </StyledTableCell>
                    ))}
                    <StyledTableCell>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 15,
                        }}
                      >
                        <StyledIconButton
                          color="primary"
                          size="medium"
                          // onClick={handleAdminDelete}
                        >
                          <DeleteIcon />
                        </StyledIconButton>
                        <Select
                          value={selectedNumber}
                          onChange={handleNumberChange}
                        >
                          <MenuItem value={1}>1</MenuItem>
                          <MenuItem value={2}>2</MenuItem>
                          <MenuItem value={3}>3</MenuItem>
                          <MenuItem value={4}>4</MenuItem>
                          <MenuItem value={5}>5</MenuItem>
                        </Select>
                        <Button variant="contained">Confirm</Button>
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography variant="body1">
              The below rules are still not available.
            </Typography>
            <Typography variant="body1">
              Rule 1: If your permission level is 5, you can adjust the person
              whose level is {"<"} 5.
            </Typography>
            <Typography variant="body1">
              Rule 2: You cannot assign a permission level which is {">"} yours
              to anyone.
            </Typography>
            <Typography variant="body1">
              Rule 3: You cannot modify your own permission level.
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <TextFieldCard state={state} />
          </Grid>
        </Grid>
      </Box>
    )
  );
}

export default PermissionCard;
