import Grid from "@mui/material/Grid";
import React, { useState, useEffect } from "react";
import { Paper, Box, Button, styled } from "@mui/material";
import { Link } from "react-router-dom";
// import { styled } from "@mui/material/styles";
// import ReminderList from "../reminder/reminderList.component";
import Waitlist from "../waitlist/waitlist.component";
import AuthWrapper from "../common/authWrapper.component";
import url from "../common/url.component";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  // margin: "40px",
}));

function HomePage(props) {
  const isLoading = AuthWrapper();
  const [level, setLevel] = useState(0);

  useEffect(() => {
    fetch(url(`/db/admin/get-level/`), {
      method: "GET",
      credentials: "include",
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setLevel(res.level);
        // console.log(res.level);
      })
      .catch((error) => console.error(error));
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2} direction="column">
        <Grid item container spacing={2}>
          {level >= 4 && (
            <Grid item xs={12} md={6}>
              <Item>
                <Waitlist authorized={!isLoading} />
              </Item>
            </Grid>
          )}
          {/* <Grid item xs={6} md={6}>
          <Item>
            <ReminderList authorized={!isLoading} />
          </Item>
        </Grid> */}
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12} md={6}>
            <Button component={Link} to={"/buyback/status"} variant="contained">
              Screen Repair
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
export default HomePage;
