export const SHIPPING_ORDER_CONSTANTS = {
  TITLE: "Shipping Orders",
  ADD_ITEM: {
    name: "New Order",
    link: "/shipping/add",
  },
  EDIT_ITEM: {
    link: "shipping/",
  },
  TABLE: {
    header: ["#", "Receiver", "Status", "QTY", "Charge", "Date"],
    key: ["id", "receiver", "status", "quantity", "charge", "created_at"],
  },
};
