import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  InputLabel,
  Button,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import url from "../../common/url.component";
import "./styles.css";
import { GRADES, FORM_TABLE_CONSTANTS } from "./options";
import Cookies from "js-cookie";
import CustomerSelector from "../../common/customer-selector";

const customCellStyle = {
  border: "1px solid black",
  padding: "5px 15px",
  textAlign: "center",
};

const customNumberCellStyle = {
  width: "100%",
  border: "none",
  padding: "5px",
  textAlign: "center",
  boxSizing: "border-box",
};

const stickyHeaderStyle = {
  position: 'sticky',
  top: 0,
  backgroundColor: 'white',
  zIndex: 1000,
  border: '1px solid black',
  padding: '5px 15px',
  textAlign: 'center',
};

const secondRowStickyHeaderStyle = {
  position: 'sticky',
  top: '50px', 
  backgroundColor: 'white',
  zIndex: 1000,
  border: '1px solid black',
  padding: '5px 15px',
  textAlign: 'center',
};

function BuybackFormInput() {
  const [data, setData] = useState(null);
  const [brands, setBrands] = useState([]);
  const [customer, setCustomer] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [charge, setCharge] = useState(0);
  const [note, setNote] = useState("");
  const [paymentMethod, setPaymentMethod] = useState(null);

  useEffect(() => {
    fetch(url(`/db/buyback/pricing-table/`), {
      method: "GET",
      credentials: "include",
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        const newData = res.data.map((item) => {
          const newItem = {
            id: item.id,
            brand: item.brand,
            model: item.model,
            qty: 0,
            total: null,
            abbrev: item.abbrev,
          };
          FORM_TABLE_CONSTANTS[item.brand].grade.forEach((one) => {
            newItem[one] = {};
          });
          return newItem;
        });
        // console.log(newData);
        setData(newData);
        // console.log(res.brands);
        setBrands(res.brands);
      })
      .catch((error) => console.error(error));
  }, []);

  const handleFormSave = () => {
    const updatedData = data.filter((row) => parseFloat(row.qty) > 0);
    if (updatedData.length === 0) {
      console.log("No data to save.");
      return;
    }
    console.log(updatedData);

    fetch(url(`/db/buyback/input-form/`), {
      method: "POST",
      headers: { "X-CSRFToken": Cookies.get("csrftoken") },
      credentials: "include",
      body: JSON.stringify({
        customer_uuid: customer,
        item_list: updatedData,
        note_list: note,
        payment_method: paymentMethod,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        window.location.reload(); // comment just for checking updatedData
      })
      .catch((error) => console.error(error));
  };

  const handleCellUpdate = (value, id, grade, type) => {
    // e.target.value, row.id, field, 'q'
    const updatedData = data.map((row) => {
      if (row.id === id) {
        const updatedRow = {
          ...row,
          [grade]: {
            ...row[grade],
            [type]: value,
          },
        };
        const [quantities, prices] = GRADES.reduce(
          (acc, header) => {
            const quantity = updatedRow[header]?.q || 0;
            const price = updatedRow[header]?.p || 0;
            if (updatedRow[header]?.p) {
              acc[0].push(parseInt(quantity));
            }
            acc[1].push(parseInt(quantity) * parseFloat(price));
            return acc;
          },
          [[], []]
        );
        updatedRow.qty = quantities.reduce((sum, qty) => sum + qty, 0);
        const total = prices.reduce((sum, price) => sum + price, 0);
        updatedRow.total = updatedRow.qty > 0 ? total.toFixed(2) : "";
        return updatedRow;
      }
      return row;
    });
    const charge = updatedData.reduce(
      (sum, item) => sum + parseFloat(item.total || 0),
      0
    );
    const quantity = updatedData.reduce((sum, item) => sum + item.qty, 0);
    setCharge(charge);
    setQuantity(quantity);
    setData(updatedData);
  };

  return (
    <Box>
      <CustomerSelector
        createBtn={false}
        setCustomer={useCallback((customer) => {
          console.log(customer);
          setCustomer(customer);
        }, [])}
      />
      {brands.map((brand, tableIdx) => (
        <div key={brand + tableIdx} style={{ marginBottom: "20px" }}>
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr>
                {FORM_TABLE_CONSTANTS[brand].header.map((item, index) => (
                  <th
                    key={brand + item}
                    colSpan={FORM_TABLE_CONSTANTS[brand].span[index]}
                    style={stickyHeaderStyle}
                  >
                    {item}
                  </th>
                ))}
              </tr>
              <tr>
                {FORM_TABLE_CONSTANTS[brand].header.map((item, index) => {
                  if (GRADES.includes(item)) {
                    return (
                      <React.Fragment key={`${brand}-${item}`}>
                        <th
                          key={`${brand}-${item}-quantity`}
                          style={secondRowStickyHeaderStyle }
                        >
                          QTY
                        </th>
                        <th
                          key={`${brand}-${item}-price`}
                          style={secondRowStickyHeaderStyle }
                        >
                          Price
                        </th>
                      </React.Fragment>
                    );
                  }
                  return <th key={brand + item} style={customCellStyle}></th>;
                })}
              </tr>
            </thead>
            <tbody>
              {data
                .filter((row) => row.brand === brand)
                .map((row) => (
                  <tr key={row.model}>
                    {FORM_TABLE_CONSTANTS[brand].header.map((field) => {
                      if (GRADES.includes(field)) {
                        return (
                          <React.Fragment key={`${row.model}-${field}`}>
                            <td style={customCellStyle}>
                              <input
                                type="number"
                                value={row[field]["q"] || ""}
                                onChange={(e) =>
                                  handleCellUpdate(
                                    Math.floor(e.target.value),
                                    row.id,
                                    field,
                                    "q"
                                  )
                                }
                                onWheel={(e) => e.target.blur()}
                                style={customNumberCellStyle}
                              />
                            </td>
                            <td style={customCellStyle}>
                              <input
                                type="number"
                                value={row[field]["p"] || ""}
                                onChange={(e) => {
                                  handleCellUpdate(
                                    e.target.value,
                                    row.id,
                                    field,
                                    "p"
                                  );
                                }}
                                onWheel={(e) => e.target.blur()}
                                style={customNumberCellStyle}
                              />
                            </td>
                          </React.Fragment>
                        );
                      } else {
                        return (
                          <td
                            key={`${row.model}-${field}`}
                            style={customCellStyle}
                          >
                            {field === brand ? row.model : row.total}
                          </td>
                        );
                      }
                    })}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      ))}
      {data && (
        <Box>
          Accumulated Amount = ${charge.toFixed(2)} for total {quantity} screens
          <TextField
            fullWidth
            label="Note"
            variant="outlined"
            multiline
            rows={4}
            margin="normal"
            value={note}
            placeholder="Note (optional) ..."
            onChange={(e) => setNote(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <InputLabel id="demo-simple-select-label">Payment Method</InputLabel>
          <Select
            labelId="payment-method"
            id="payment_method"
            value={paymentMethod ? paymentMethod : ""}
            // label="payment_method"
            onChange={(e) => setPaymentMethod(e.target.value)}
            // defaultValue=""
            style={{ width: "100%" }}
          >
            <MenuItem value="Store Credit">Store Credit</MenuItem>
            <MenuItem value="Wire Transfer">Wire Transfer</MenuItem>
            <MenuItem value="Zelle">Zelle</MenuItem>
            <MenuItem value="Cash App">Cash App</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
          </Select>
          <Button
            variant="contained"
            color="primary"
            onClick={handleFormSave}
            style={{ marginTop: "20px" }}
            disabled={!paymentMethod || !customer || quantity <= 0}
          >
            Save
          </Button>
        </Box>
      )}
    </Box>
  );
}

export default BuybackFormInput;
