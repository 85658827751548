import CustomerSelector from "../common/customer-selector";
import { Box, Typography } from "@mui/material";
import { useState, useCallback } from "react";
import Cookies from "js-cookie";
import url from "../common/url.component";
import { useNavigate } from "react-router-dom";

function InvoiceGenerator(props) {
  const [customer, setCustomer] = useState("");
  const navigate = useNavigate();

  const handleNewButtonClick = () => {
    console.log("New Invoice Btn");
    fetch(url(`/db/invoices/add/`), {
      method: "POST",
      headers: { "X-CSRFToken": Cookies.get("csrftoken") },
      credentials: "include", // MUST
      body: JSON.stringify({
        uuid: customer,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        navigate("/invoices/" + res.message);
      })
      .catch((error) => {
        console.error("Error occurred during status update:", error);
      });
  };

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        // minHeight: '100vh',
      }}
    >
      <Typography variant="h4" paddingBottom={"1%"}>
        New Invoice
      </Typography>
      <CustomerSelector
        createBtn={true}
        onNewButtonClick={handleNewButtonClick}
        setCustomer={useCallback((customer) => {
          console.log(customer);
          setCustomer(customer);
        }, [])}
      />
    </Box>
  );
}

export default InvoiceGenerator;
