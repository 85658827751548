import React, { useEffect, useState } from "react";
import url from "../../common/url.component";
import AuthWrapper from "../../common/authWrapper.component";
import BaseList from "../../common/BaseList";
import { PRODUCT_LIST_CONSTANTS } from "./options";

function ProductList() {
  const [isLoading, setIsLoading] = useState(true);
  const isLoading2 = AuthWrapper();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [data, setData] = useState(null);
  const [entityStatus, setEntityStatus] = useState({});

  useEffect(() => {
    fetch(url(`/db/inventory/products/?page=${page}`), {
      method: "GET",
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        // console.log(res.items);
        setData(res.items);
        setEntityStatus(
          res.items.reduce((statusObj, item) => {
            statusObj[item.uuid] = item.publish_status_b;
            return statusObj;
          }, {})
        );
        setTotalPages(res.total_pages);
        setIsLoading(false);
      })
      .catch((error) => console.error(error));
  }, [page]);

  if (isLoading2 || isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <BaseList
      data={data}
      options={PRODUCT_LIST_CONSTANTS}
      hasTab={false}
      hasNewBtn={true}
      hasDownloadBtn={true}
      hasUploadBtn={true}
      hasSearchBar={true}
      hasTable={true}
      page={page}
      setPage={setPage}
      totalPages={totalPages}
      hasEnableBtn={true}
      entityStatus={entityStatus}
      setEntityStatus={setEntityStatus}
    />
  );
}
export default ProductList;
