export const CUSTOMER_LIST_CONSTANTS = {
  TITLE: "Customers",
  ADD_ITEM: {
    name: "New Customer",
    link: "/customers/add",
  },
  EDIT_ITEM: {
    link: "/customers/",
  },
  CHANGE_STATUS: {
    link: "/db/customers/activate/",
  },
  FILE_UPLOAD: {
    link: "/db/customers/import/",
  },
  FILE_DOWNLOAD: {
    link: "/db/customers/export/",
  },
  TABLE: {
    header: ["Name / Business", "Email", "Phone", "Last Login"],
    key: ["company", "email", "phone", "last_login"],
  },
  TAB: {
    key: [0, 1, 2],
    value: ["all", "wholesale", "retail"]
  },
};

export const CUSTOMER_CARD_CONSTANTS = {
  TITLE: "Customer Detail",
  MAIN_PAGE: "/customers",
  ADD_ITEM: {
    link: "/db/customers/add/",
  },
  UPDATE_ITEM: {
    link: "/db/customers/update/",
  },
  IMAGE: {
    name: "Tax File Information",
    key: "tax_url",
    size: 800,
  },
  FORMULA: {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    company: "",
    tax_id: "",
    city: "",
    state: "",
    zipcode: "",
  },
  INPUTS: [
    {
      id: "0",
      name: "First Name",
      key: "first_name",
      // type: "text",
      placeholder: "First Name",
    },
    {
      id: "1",
      name: "Last Name",
      key: "last_name",
      // type: "text",
      placeholder: "Last Name",
    },
    {
      id: "2",
      name: "Email",
      key: "email",
      // type: "number",
      placeholder: "Email",
    },
    {
      id: "3",
      name: "Phone",
      key: "phone",
      // type: "number",
      placeholder: "Phone",
    },
    {
      id: "4",
      name: "Company",
      key: "company",
      // type: "text",
      placeholder: "Business Name",
    },
    {
      id: "5",
      name: "Tax ID",
      key: "tax_id",
      // type: "number",
      placeholder: "Tax ID",
    },
    {
      id: "6",
      name: "Address Line 1",
      key: "address1",
      // type: "number",
      placeholder: "Address Line 1",
    },
    {
      id: "7",
      name: "Address Line 2",
      key: "address2",
      // type: "number",
      placeholder: "Optional",
    },
    {
      id: "8",
      name: "City",
      key: "city",
      // type: "number",
      placeholder: "City",
    },
    {
      id: "9",
      name: "State",
      key: "state",
      // type: "number",
      placeholder: "State",
    },
    {
      id: "10",
      name: "Zipcode",
      key: "zipcode",
      // type: "number",
      placeholder: "Zipcode",
    },
  ],
};
