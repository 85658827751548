export const INVOICE_LIST_CONSTANTS = {
  TITLE: "Invoices",
  ADD_ITEM: {
    name: "New Invoice",
    link: "/invoices/add",
  },
  EDIT_ITEM: {
    link: "/invoices/",
  },
  FILE_UPLOAD: {
    link: "/db/invoice/import-data/",
  },
  FILE_DOWNLOAD: {
    link: "/db/invoice/export-data/",
  },
  TABLE: {
    header: ["#", "Customer", "Items", "Source", "Ticketed", "Paid", "Date", "Total"],
    key: ["id", "company", "subject", "type", "ticketed", "paid", "created_at", "charge"],
  },
  TAB: {
    key: [0, 1, 2, 3],
    value: ["all", "onsite", "wholesale", "retail"]
  },
};

export const INVOICE_CARD_CONSTANTS = {
  TITLE: "Invoice Detail",
  MAIN_PAGE: "/invoices",
  ADD_ITEM: {
    link: "/db/invoice/add/",
  },
  UPDATE_ITEM: {
    link: "/db/invoice/update/",
  },
  UPDATE_DESC: {
    link: "/db/invoices/update-notes/",
  },
  DELETE_ITEM: {
    link: "/db/invoice/delete/",
  },
  IMAGE: {
    name: "Barcode / QR Code",
    key: "barcode_url",
    size: 400,
  },
  FORMULA: {
    product_name: "",
    uuid: "",
    price: "",
    quantity: "",
    category: "",
  },
  INPUTS: [
    // {
    //   id: "0",
    //   name: "Customer",
    //   key: "customer",
    //   // type: "text",
    //   placeholder: "based on customer_uuid",
    // },
    {
      id: "0",
      name: "Status",
      key: "status",
      // type: "text",
      placeholder: "Status",
    },
    {
      id: "1",
      name: "Source",
      key: "type",
      // type: "text",
      placeholder: "Source",
    },
    {
      id: "2",
      name: "Paid",
      key: "paid",
      // type: "number",
      placeholder: "Paid",
    },
    // {
    //   id: "4",
    //   name: "Quantity",
    //   key: "quantity",
    //   // type: "number",
    //   placeholder: "Quantity",
    // },
  ],
};
