import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import url from "../common/url.component";

function LoginForm() {
  const [message, setMessage] = useState("");
  const [token, setToken] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [emailField, setEmailField] = useState("");

  const handleEmailChange = (event) => {
    setEmailField(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get("email");
    const password = data.get("password");
    if (!email || !password) {
      setMessage("Email and password are required");
      return;
    } else {
      setMessage("");
    }
    fetch(url(`/db/admin/login/`), {
      method: "POST",
      headers: { "X-CSRFToken": token },
      credentials: "include",
      body: JSON.stringify({ email: email, password: password }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.message === "success") {
          Cookies.set("isLoggedIn", "true", { expires: 7, path: "/" });
          fetchToken();
          window.location.href = "/";
        } else {
          setMessage(res.message);
        }
      })
      .catch((error) => console.error(error));
  };

  const handleDebugClick = () => {
    fetch(url(`/db/admin/create/`), {
      method: "GET",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((res) => {
        setMessage(res.message);
      })
      .catch((error) => console.error(error));
  };

  const fetchToken = () => {
    fetch(url(`/db/admin/token/`), {
      method: "GET",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((res) => {
        setToken(res.token);
        setIsLoading(false);
        Cookies.set("csrftoken", res.token, { expires: 7, path: "/" });
      });
  };

  const handlePasswordReset = (event) => {
    if (!emailField) {
      setMessage("Enter your email");
      return;
    } else {
      setMessage("");
    }
    fetch(url(`/db/admin/reset/`), {
      method: "PUT",
      headers: { "X-CSRFToken": token },
      credentials: "include",
      body: JSON.stringify({ email: emailField }),
    })
      .then((res) => res.json())
      .then((res) => {
        setMessage(res.message);
      });
  };

  useEffect(() => {
    if (Cookies.get("isLoggedIn")) {
      fetch(url("/db/admin/status/"), {
        method: "GET",
        credentials: "include", // MUST
      }).then((res) => {
        if (res.status === 200) {
          window.location.href = "/";
        } else {
          setIsLoading(false);
        }
      });
    } else {
      fetchToken();
    }
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Sign In
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={handleEmailChange}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2" onClick={handlePasswordReset}>
                Forgot password?
              </Link>
            </Grid>
            {/* <Grid item>
              <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid> */}
          </Grid>
        </Box>
        <Typography
          sx={{
            marginTop: 8,
            color: "red",
          }}
          component="h1"
          variant="h5"
        >
          {message}
          <Button onClick={handleDebugClick}> Debug </Button>
        </Typography>
      </Box>
    </Container>
  );
}

export default LoginForm;
