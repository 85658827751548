import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./home/HomePage";
import Navbar from "./navigation/navigation.component";
// import ReminderList from "./reminder/reminderList.component";
// import ReminderCard from "./reminder/reminderCard.component";
import CustomerList from "./customer/CustomerList";
import CustomerCard from "./customer/customerCard.component";
import InvoiceList from "./invoice/InvoiceList";
import InvoiceGenerator from "./invoice/invoice-generator";
import InvoiceCard from "./invoice/invoiceCard.component";
import TicketList from "./ticket/TicketList";
import TicketGenerator from "./ticket/ticket-generator";
import TicketCard from "./ticket/ticketCard.component";
import ProductList from "./inventory/product/ProductList";
import ProductCard from "./inventory/product/productCard.component";
import ServiceList from "./inventory/service/ServiceList";
import ServiceCard from "./inventory/service/serviceCard.component";
import Waitlist from "./waitlist/waitlist.component";
import Applicant from "./waitlist/applicant.component";
import LoginForm from "./login/loginForm.component";
import Box from "@mui/material/Box";
import ProfileCard from "./admin/profileCard.component";
import PermissionCard from "./admin/permissionCard.component";
import InsuranceList from "./insurance/insuranceList.component";
import BuybackMenu from "./buyback/BuybackMenu";
import BuybackApplicationList from "./buyback/application-management/BuybackApplicationList";
import BuybackApplicationCard from "./buyback/application-management/BuybackApplicationCard";
import BuybackFormInput from "./buyback/form-management/BuybackFormInput";
import BuybackFormList from "./buyback/form-management/BuybackFormList";
import BuybackFormCard from "./buyback/form-management/BuybackFormCard";
import BuybackPriceAdjustment from "./buyback/price-adjustment/BuybackPriceAdjustment";
import CrackedScreenList from "./buyback/cracked-screen-management/CrackedScreenList";
import CrackedScreenCard from "./buyback/cracked-screen-management/CrackedScreenCard";
import BuybackShippingList from "./buyback/shipping-order-management/BuybackShippingList";

export const AuthContext = React.createContext();

function PageNotFound() {
  return (
    <div>
      <p>404 Page not found</p>
    </div>
  );
}

function App() {
  return (
    <Router>
      <Navbar />
      <Box sx={{ margin: "40px" }}>
        <Routes>
          <Route exact path="/login" element={<LoginForm />} />

          <Route path="*" element={<PageNotFound />} />
          <Route path="/" exact element={<HomePage />} />

          <Route path="/customers" element={<CustomerList />} />
          <Route path="/customers/:uuid" element={<CustomerCard />} />
          <Route path="/customers/add" element={<CustomerCard />} />

          <Route path="/invoices" element={<InvoiceList />} />
          <Route path="/invoices/:uuid" element={<InvoiceCard />} />
          <Route path="/invoices/add" element={<InvoiceGenerator />} />

          <Route path="/tickets" element={<TicketList />} />
          <Route path="/tickets/:uuid" element={<TicketCard />} />
          <Route path="/tickets/add" element={<TicketGenerator />} />

          <Route path="/products" element={<ProductList />} />
          <Route path="/products/:uuid" element={<ProductCard />} />
          <Route path="/products/add" element={<ProductCard />} />

          <Route path="/services" element={<ServiceList />} />
          <Route path="/services/:uuid" element={<ServiceCard />} />
          <Route path="/services/add" element={<ServiceCard />} />

          <Route path="/waitlist" element={<Waitlist />} />
          <Route path="/waitlist/:uuid" element={<Applicant />} />

          {/* <Route path="/reminders" element={<ReminderList />} />
          <Route path="/reminders/:uuid" element={<ReminderCard />} />
          <Route path="/reminders/add" element={<ReminderCard />} /> */}

          <Route path="/profile" element={<ProfileCard />} />
          <Route path="/permissions" element={<PermissionCard />} />

          <Route path="/buyback" element={<BuybackMenu />} />
          <Route path="/buyback/applications" element={<BuybackApplicationList />} />
          <Route path="/buyback/applications/:uuid" element={<BuybackApplicationCard />} />
          <Route path="/buyback/adjustment" element={<BuybackPriceAdjustment />} />
          <Route path="/buyback/input" element={<BuybackFormInput />} />
          <Route path="/buyback/history" element={<BuybackFormList />} />
          <Route path="/buyback/history/:uuid" element={<BuybackFormCard />} />
          <Route path="/buyback/status" element={<CrackedScreenList />} />
          <Route path="/buyback/status/:uuid" element={<CrackedScreenCard />} />
          <Route path="/buyback/shipping" element={<BuybackShippingList />} />

          <Route path="/insurance" element={<InsuranceList />} />
        </Routes>
      </Box>
    </Router>
  );
}

export default App;
