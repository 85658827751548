import React, { useEffect, useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Box,
  Paper,
  Typography,
} from "@mui/material";
import url from "../../common/url.component";
import AuthWrapper from "../../common/authWrapper.component";
import BaseList from "../../common/BaseList";
import { BUYBACK_LIST_CONSTANTS, PRICING_TABLE_CONSTANTS } from "./options";

function BuybackPriceAdjustment(props) {
  const [isLoading, setIsLoading] = useState(false);
  const isLoading2 = AuthWrapper();
  const [data, setData] = useState(null);
  const [brand, setBrand] = useState("iPhone");
  const [brands, setBrands] = useState([]);

  const handleBrandSelect = (name) => {
    setBrand(name);
  };

  useEffect(() => {
    fetch(url(`/db/buyback/pricing-table/`), {
      method: "GET",
      credentials: "include",
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setData(res.data);
        setBrands(res.brands);
        setIsLoading(false);
      })
      .catch((error) => console.error(error));
  }, []);

  if (isLoading2 || isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Box>
      <BaseList
        data={data}
        options={BUYBACK_LIST_CONSTANTS}
        hasTab={false}
        hasNewBtn={false}
        hasDownloadBtn={false}
        hasUploadBtn={true}
        hasSearchBar={false}
        hasTable={false}
      />
      <Typography>
        Preview - update the pricing table by using the upload button.
        <br />
        The filename must start with "Buyback Pricing Table" and the file type must be
        .xlsx.
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "40px",
        }}
      >
        {data && (
          <Box
            sx={{
              margin: { xs: "10px", md: "20px" },
              display: "flex",
              flexDirection: "row",
            }}
          >
            {brands.map((item, index) => (
              <Box sx={{ marginLeft: "1%" }} key={index + item}>
                <Button
                  variant="contained"
                  onClick={() => handleBrandSelect(item)}
                >
                  {item}
                </Button>
              </Box>
            ))}
          </Box>
        )}
        {data && (
          <TableContainer
            component={Paper}
            sx={{ width: { xs: "98%", md: "60%" }, marginBottom: "5%" }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  {PRICING_TABLE_CONSTANTS[brand].header.map((item, index) => (
                    <TableCell key={"table-header-" + item + index}>
                      {item}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data
                  .filter((row) => row["brand"] === brand)
                  .map((row, index) => (
                    <TableRow key={"table-row-" + row.model + index}>
                      {PRICING_TABLE_CONSTANTS[brand].key.map((item, index) => (
                        <TableCell key={"table-cell-" + item + index}>
                          {item === "model" ? row[item] : "$" + row[item]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </Box>
  );
}

export default BuybackPriceAdjustment;
