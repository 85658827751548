export const PRODUCT_LIST_CONSTANTS = {
  TITLE: "Products",
  ADD_ITEM: {
    name: "New Item",
    link: "/products/add",
  },
  EDIT_ITEM: {
    link: "/products/",
  },
  CHANGE_STATUS: {
    link: "/db/inventory/publish/",
  },
  FILE_UPLOAD: {
    link: "/db/inventory/import-products/",
  },
  FILE_DOWNLOAD: {
    link: "/db/inventory/export-products/",
  },
  TABLE: {
    header: ["Name", "Price", "Quantity", "Category", "Label"],
    key: ["product_name", "price_b", "quantity", "category"],
  },
};

export const PRODUCT_CARD_CONSTANTS = {
  TITLE: "Product Detail",
  MAIN_PAGE: "/products",
  ADD_ITEM: {
    link: "/db/inventory/add-product/",
  },
  UPDATE_ITEM: {
    link: "/db/inventory/update/",
  },
  UPDATE_DESC: {
    link: "/db/inventory/desc/",
  },
  DELETE_ITEM: {
    link: "/db/inventory/delete/",
  },
  IMAGE: {
    name: "Barcode / QR Code",
    key: "barcode_url",
    size: 400,
  },
  FORMULA: {
    product_name: "",
    uuid: "",
    price: "",
    quantity: "",
    category: "",
  },
  INPUTS: [
    {
      id: "0",
      name: "Product Name",
      key: "product_name",
      // type: "text",
      placeholder: "Product Name",
    },
    {
      id: "1",
      name: "Brand",
      key: "brand",
      // type: "text",
      placeholder: "Brand",
    },
    {
      id: "2",
      name: "Category",
      key: "category",
      // type: "text",
      placeholder: "Category",
    },
    {
      id: "3",
      name: "Price",
      key: "price_b",
      // type: "number",
      placeholder: "Price",
    },
    {
      id: "4",
      name: "Quantity",
      key: "quantity",
      // type: "number",
      placeholder: "Quantity",
    },
  ],
};
