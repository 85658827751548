export const BUYBACK_LIST_CONSTANTS = {
  TITLE: "Buyback Pricing Table",
  FILE_UPLOAD: {
    link: "/db/buyback/price-adjustment/import/",
  },
  FILE_DOWNLOAD: {
    link: "/db/buyback/price-adjustment/export/",
  },
  EDIT_ITEM: {
    link: "/buyback/",
  },
};

export const PRICING_TABLE_CONSTANTS = {
  iPhone: {
    header: ["Model", "A/B/C", "Shadow", "NT", "Broken"],
    key: ["model", "grade_abc", "grade_s", "no_touch", "broken"],
  },
  iPad: {
    header: ["Model", "A/B", "C Grade", "BL"],
    key: ["model", "grade_ab", "grade_c", "backlight"],
  },
  Samsung: {
    header: ["Model", "A Grade", "B Grade", "C Grade", "NT"],
    key: ["model", "grade_a", "grade_b", "grade_c", "no_touch"],
  },
  iWatch: {
    header: ["Model", "A/B", "C Grade"],
    key: ["model", "grade_ab", "grade_c"],
  },
};
