// dev
let url = (path) => `http://localhost:8000${path}`;

if (process.env.NODE_ENV !== "development") {
  // deploy
  // url = (path) => `https://system.lenwellscreen.com${path}`;
  url = (path) => `https://system.lenwellcorp.com${path}`;
}

export default url;
