import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useParams, useNavigate } from "react-router-dom";
import { PROFILE_CARD_CONSTANTS } from "./options";
import Grid from "@mui/material/Grid";
import url from "../common/url.component";
import TextFieldCard from "../common/cardComponents/textFieldCard.component";

function ProfileCard(props) {
  const { uuid } = useParams(false);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const state = {
    uuid: true,
    data,
    options: PROFILE_CARD_CONSTANTS,
  };

  useEffect(() => {
    fetch(url("/db/admin/profile/"), {
      method: "GET",
      credentials: "include",
    })
      .then((res) => {
        if (res.status !== 200) {
          navigate("/");
          return;
        }
        return res.json();
      })
      .then((res) => {
        // console.log(res.customers);
        if (res) {
          setData(res.employee);
          setIsLoading(false);
        }
      })
      .catch((error) => console.error(error));
  }, [uuid, navigate]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <TextFieldCard state={state} />
        </Grid>
      </Grid>
    </Box>
  );
}

export default ProfileCard;
