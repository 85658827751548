import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useLocation, Link } from "react-router-dom";
import url from "../common/url.component";
import AuthWrapper from "../common/authWrapper.component";
import { Styled2TableCell } from "../common/globalStyles.component";
import { StyledTableRow } from "../common/globalStyles.component";

function Waitlist(props) {
  const isLoading2 = AuthWrapper(props.authorized);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const isWaitListPage = location.pathname === "/waitlist";

  useEffect(() => {
    fetch(url("/db/waitlist/"), {
      method: "GET",
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        // console.log(res.waitlist);
        setData(res.waitlist);
        setIsLoading(false);
      })
      .catch((error) => console.error(error));
  }, []);

  if (isLoading2 || isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <Typography variant="h3">New Applicants</Typography>
        <Box sx={{ display: "flex", gap: 1 }}>
          {!isWaitListPage && (
            <Button variant="contained" component={Link} to={"/waitlist"}>
              VIEW
            </Button>
          )}
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 300 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <Styled2TableCell>Name</Styled2TableCell>
              <Styled2TableCell>Company</Styled2TableCell>
              <Styled2TableCell>Time</Styled2TableCell>
              <Styled2TableCell>Action</Styled2TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => (
              <StyledTableRow key={row.uuid}>
                <Styled2TableCell component="th" scope="row">
                  {row.last_name + ", " + row.first_name}
                </Styled2TableCell>
                <Styled2TableCell>{row.company}</Styled2TableCell>
                <Styled2TableCell>
                  {new Date(row.created_at).toLocaleString()}
                </Styled2TableCell>
                <Styled2TableCell>
                  {/* <CheckIcon style={{ color: "green" }} />
                <CloseIcon style={{ color: "red" }} /> */}
                  <Button
                    variant="contained"
                    component={Link}
                    to={"/waitlist/" + row.uuid}
                  >
                    VIEW
                  </Button>
                </Styled2TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
export default Waitlist;
