import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import url from "../common/url.component";
import { INVOICE_CARD_CONSTANTS } from "./options";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import InvoiceSubCard from "./invoiceSubCard.component";
// import DescriptionCard from "../common/cardComponents/descriptionCard.component";
import LineItemContainer from "./line-item-container";
import DescriptionIcon from "@mui/icons-material/Description";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
// import LinkedTickets from "./linked-tickets";

function InvoiceCard(props) {
  const { uuid } = useParams(false);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const state = { uuid, data, options: INVOICE_CARD_CONSTANTS };

  useEffect(() => {
    if (uuid) {
      fetch(url("/db/invoices/" + uuid), {
        method: "GET",
      })
        .then((res) => {
          if (res.status !== 200) {
            navigate("/invoices");
            return;
          }
          return res.json();
        })
        .then((res) => {
          // console.log(res.items);
          if (res) {
            setData(res.invoices);
            setIsLoading(false);
          }
        })
        .catch((error) => console.error(error));
    }
  }, [uuid, navigate]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <Typography variant="h3">Invoice # {data.id}</Typography>
        <Box sx={{ display: "flex", gap: 1 }}>
          {data.receipt_url && (
            <IconButton
              href={data.receipt_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <DescriptionIcon />
            </IconButton>
          )}
          <Button
            variant="contained"
            component={Link}
            to={INVOICE_CARD_CONSTANTS.MAIN_PAGE}
          >
            BACK
          </Button>
          {/* {uuid && (
              <Button variant="contained" onClick={handleDelete}>
                DELETE
              </Button>
            )} */}
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6} md={6}>
          <InvoiceSubCard state={state} />
        </Grid>
        {/* <Grid item xs={6} md={6}>
          <DescriptionCard state={state} />
        </Grid> */}
        {/* <Grid item xs={12} md={12}>
          <LinkedTickets state={state} />
        </Grid> */}
        <Grid item xs={12} md={12}>
          <LineItemContainer state={state} setData={setData} />
        </Grid>
      </Grid>
    </Box>
  );
}

export default InvoiceCard;
