import React, { createRef, useState } from "react";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
// import { useNavigate } from "react-router-dom";
import url from "../common/url.component";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import { StyledTicketPageBox } from "../common/globalStyles.component";

const TICKET_STATUS = [
  "In Progress",
  "Please Call Us",
  "Ready to Pick up",
  "Invoiced",
];

function TicketSubCard(props) {
  const { data, options } = props.state;
  // const [message, setMessage] = useState("");
  const inputs = options.INPUTS;
  const inputRefs = inputs.map(() => createRef());
  // const navigate = useNavigate();
  const [ticketStatusValue, setTicketStatusValue] = useState(null);

  const handleTicketStatusChange = (event) => {
    const newValue = event.target.value;
    fetch(url(`/db/tickets/update-status/`), {
      method: "PUT",
      headers: { "X-CSRFToken": Cookies.get("csrftoken") },
      credentials: "include",
      body: JSON.stringify({
        status: newValue,
        uuid: data.uuid,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        //   setMessage(res.message);
        setTicketStatusValue(newValue);
        window.location.reload();
      })
      .catch((error) => console.error(error));
  };

  return (
    <Card sx={{ flexGrow: 1 }}>
      <CardHeader
        title={options.TITLE}
        style={{ backgroundColor: "#899499", color: "white" }}
      />
      <CardContent>
        <Box
          component="form"
          noValidate
          //   onSubmit={handleFormSubmit}
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              flex: 1,
            }}
          >
            {inputs.map((input) => (
              <Box
                key={input.id + data.uuid}
                style={{ display: "flex", width: "100%" }}
              >
                <StyledTicketPageBox>{input.name}</StyledTicketPageBox>
                {input.key !== "status" ? (
                  <TextField
                    defaultValue={data[input.key]}
                    //   onChange={(e) => {
                    //   }}
                    disabled
                    variant="outlined"
                    style={{ flex: 1 }}
                    inputRef={inputRefs[input.id]}
                  />
                ) : (
                  <Select
                    value={
                      ticketStatusValue ? ticketStatusValue : data[input.key]
                    }
                    style={{ flex: 1 }}
                    onChange={handleTicketStatusChange}
                  >
                    {TICKET_STATUS.map((item, index) => {
                      return (
                        <MenuItem key={item + index} value={item}>
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
              </Box>
            ))}
            <Box style={{ display: "flex", width: "100%" }}>
              <StyledTicketPageBox>Customer</StyledTicketPageBox>
              <Box
                style={{
                  padding: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                component={Link}
                to={"/customers/" + data.customer_uuid}
              >
                {data.customer_company
                  ? data.customer_company
                  : data.customer_name}
              </Box>
            </Box>
            <Box style={{ display: "flex", width: "100%" }}>
              <StyledTicketPageBox>Invoiced</StyledTicketPageBox>
              <Box
                style={{
                  padding: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textDecoration: "none",
                }}
                component={Link}
                to={data.invoice_uuid ? "/invoices/" + data.invoice_uuid : null}
              >
                {data.invoice_id ? "# " + data.invoice_id : "-"}
              </Box>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export default TicketSubCard;
