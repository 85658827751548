import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  //   Container,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  //   IconButton,
  //   Input,
  Box,
  //   Divider,
  Paper,
  Divider,
  DialogActions,
  TextField,
  CircularProgress,
} from "@mui/material";
import url from "../common/url.component";
import Cookies from "js-cookie";
import TablePagination from "./tablePagination";
// import { type } from "@testing-library/user-event/dist/type";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { UploadFile } from "@mui/icons-material";

const cellStyleForNone = {
  borderBottom: "none",
};

function InsuranceList(props) {
  const [page_lable, setPage_label] = useState("user");
  const [code, setCode] = useState([]);
  const [page, setPage] = useState(1);
  const rowsPerPage = 50;
  const [insurance, setInsurance] = useState([]);
  const [open, setOpen] = useState(false); //for dialoge
  const [selectedImage, setSelectedImage] = useState(null);
  const [editingData, setEditingData] = useState(null);
  const [edit, setEdit] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [selectData, setSelectData] = useState("Pending Review");
  const [startDate, setStartDate] = useState(Date.now());
  const [endDate, setEndDate] = useState(Date.now());
  const [commentData, setCommentData] = useState("");
  const [approvedList, setApprovedList] = useState([]);
  const [approvedUserInfo, setApprovedUserInfo] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileissUpload, setFileIsUpload] = useState(false);

  const totalPages = Math.ceil(code.length / rowsPerPage);
  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const displayedData = code.slice(startIndex, endIndex);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleFileUpload = () => {
    const formData = new FormData();
    formData.append("excelFile", selectedFile);
    setFileIsUpload(true);
    fetch(url("/m/coupon/importcode/"), {
      method: "POST",
      body: formData,
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        // 处理后端返回的数据
        // console.log(data);
        if (data.result === "Exception") {
          window.alert(data.message);
        }
        setFileIsUpload(false);
      })
      .catch((error) => {
        // 处理错误
        console.error("Error:", error);
      });
  };

  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   const formData = new FormData();
  //   formData.append('excelFile', file);

  // };

  const openImageDialog = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedImage(null);
    setOpen(false);
    setEdit(false);
  };
  const handleEditClick = (rowData) => {
    console.log(rowData);
    setEditingData(rowData);
    setEdit(true);
    setOpen(true);
  };
  const handleSaveEdit = () => {
    // fetch backend api and refresh
    // Implement save edit logic here
    // Update your data with the edited values in editingData
    // You can use state or a callback function to update the data
    fetch(url("/m/insurance/updateinsurance/"), {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": Cookies.get("token"),
      },
      body: JSON.stringify({
        start_date: startDate,
        end_date: endDate,
        status: selectData,
        comment: commentData,
        uuid: editingData.fields.user_id,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        console.log(res);
        window.location.reload();
      });

    console.log("Saved edit:", editingData);
    setEditingData(null);
    setOpen(false);
  };
  const handleSelectChange = (event) => {
    setSelectData(event.target.value);
  };
  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };
  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };
  const handleCommentData = (e) => {
    setCommentData(e.target.value);
  };

  useEffect(() => {
    fetch(url("/m/insurance/getwaitlistinsurance/"), {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": Cookies.get("token"),
      },
      body: JSON.stringify({}),
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        // console.log(res);
        // console.log(typeof res);
        // console.log(res.message);
        // console.log(typeof res.message);
        if (res.result === "success") {
          var json_data = JSON.parse(res.message);
          console.log(json_data);
          setInsurance(json_data);
          console.log(res.userInfo);
          console.log(typeof res.userInfo);
          setUserInfo(res.userInfo);
        }
      });
  }, []);

  useEffect(() => {
    // for approved list
    fetch(url("/m/insurance/getapprovedinsurance/"), {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": Cookies.get("token"),
      },
      body: JSON.stringify({}),
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        // console.log(res);
        // console.log(typeof res);
        // console.log(res.message);
        // console.log(typeof res.message);
        if (res.result === "success") {
          var json_data = JSON.parse(res.message);
          console.log(json_data);
          setApprovedList(json_data);
          console.log(res.userInfo);
          console.log(typeof res.userInfo);
          setApprovedUserInfo(res.userInfo);
        }
      });
  }, []);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  const iPhoneModels = [
    "iPhone 15 Pro Max",
    "iPhone 15 Plus",
    "iPhone 15 Pro",
    "iPhone 15",
    "iPhone 14 Pro Max",
    "iPhone 14 Plus",
    "iPhone 14 Pro",
    "iPhone 14",
    "iPhone 13 Pro Max",
    "iPhone 13 Pro",
    "iPhone 13",
    "iPhone 13 Mini",
    "iPhone 12 Pro Max",
    "iPhone 12 Pro",
    "iPhone 12",
    "iPhone 12 Mini",
    "iPhone 11 Pro Max",
    "iPhone 11 Pro",
    "iPhone 11",
    "iPhone XS Max",
    "iPhone XR",
    "iPhone X/XS",
  ];

  const handleUserButton = (label) => {
    if (label === "user") {
      setPage_label("user");
    } else if (label === "code") {
      setPage_label("code");
    } else {
      setPage_label("None");
    }
  };
  const handleCodeButton = (model) => {
    fetch(url("/m/coupon/getallcode/"), {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-CSRFToken": Cookies.get("token"),
      },
      body: JSON.stringify({ model: model }),
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        // console.log(res.message);
        setCode(JSON.parse(res.message));
      });
  };
  return (
    <Box
      sx={{
        display: "felx",
        flexDirection: "column",
      }}
    >
      <Button
        onClick={() => {
          handleUserButton("user");
        }}
      >
        Users
      </Button>
      <Button
        onClick={() => {
          handleUserButton("code");
        }}
      >
        Codes
      </Button>
      <Divider sx={{ marginTop: "20px" }} />
      {page_lable === "user" ? (
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Box sx={{ flex: 1 }}>
            waitlist
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Account</TableCell>
                    <TableCell>Coverage Time</TableCell>
                    <TableCell>Start Date</TableCell>
                    <TableCell>End Date</TableCell>
                    <TableCell>Phone Image</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Comment</TableCell>
                    <TableCell>Edit</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {insurance.length === 0 ? (
                    <TableRow>
                      <TableCell style={cellStyleForNone}>None</TableCell>
                    </TableRow>
                  ) : (
                    insurance.map((item, index) => (
                      <TableRow>
                        <TableCell>{userInfo[item.fields.user_id]}</TableCell>
                        <TableCell>{item.fields.coverage_time} Year</TableCell>
                        <TableCell>
                          {item.fields.start_date === null
                            ? "None"
                            : item.fields.start_date}
                        </TableCell>
                        <TableCell>
                          {item.fields.end_date === null
                            ? "None"
                            : item.fields.end_date}
                        </TableCell>
                        <TableCell>
                          <img
                            alt=""
                            src={JSON.parse(item.fields.iphone_image)["image1"]}
                            style={{ maxWidth: "60%" }}
                            onClick={() =>
                              openImageDialog(
                                JSON.parse(item.fields.iphone_image)["image1"]
                              )
                            }
                          />
                          <img
                            alt=""
                            src={JSON.parse(item.fields.iphone_image)["image2"]}
                            style={{ maxWidth: "60%" }}
                            onClick={() =>
                              openImageDialog(
                                JSON.parse(item.fields.iphone_image)["image2"]
                              )
                            }
                          />
                        </TableCell>
                        <TableCell>{item.fields.status}</TableCell>
                        <TableCell>
                          {item.fields.comment === ""
                            ? "None"
                            : item.fields.comment}
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="outlined"
                            onClick={() => handleEditClick(item)}
                          >
                            Edit
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Box sx={{ flex: 1, marginLeft: "25px" }}>
            approved
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Account</TableCell>
                    <TableCell>Coverage Time</TableCell>
                    <TableCell>Start Date</TableCell>
                    <TableCell>End Date</TableCell>
                    <TableCell>Phone Image</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {approvedList.length === 0 ? (
                    <TableRow>
                      <TableCell style={cellStyleForNone}>None</TableCell>
                    </TableRow>
                  ) : (
                    approvedList.map((item, index) => (
                      <TableRow>
                        <TableCell>
                          {approvedUserInfo[item.fields.user_id]}
                        </TableCell>
                        <TableCell>{item.fields.coverage_time} Year</TableCell>
                        <TableCell>
                          {item.fields.start_date === null
                            ? "None"
                            : item.fields.start_date}
                        </TableCell>
                        <TableCell>
                          {item.fields.end_date === null
                            ? "None"
                            : item.fields.end_date}
                        </TableCell>
                        <TableCell>
                          <img
                            alt=""
                            src={JSON.parse(item.fields.iphone_image)["image1"]}
                            style={{ maxWidth: "60%" }}
                            onClick={() =>
                              openImageDialog(
                                JSON.parse(item.fields.iphone_image)["image1"]
                              )
                            }
                          />
                          <img
                            alt=""
                            src={JSON.parse(item.fields.iphone_image)["image2"]}
                            style={{ maxWidth: "60%" }}
                            onClick={() =>
                              openImageDialog(
                                JSON.parse(item.fields.iphone_image)["image2"]
                              )
                            }
                          />
                        </TableCell>
                        <TableCell>{item.fields.status}</TableCell>
                        {/* <TableCell>
                            {item.fields.comment === ""
                              ? "None"
                              : item.fields.comment}
                          </TableCell> */}
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Dialog open={open} onClose={handleClose} maxWidth="lg">
            {/* Use maxWidth property to adjust the width */}
            <DialogTitle>
              {selectedImage && "Full-Size Image"}
              {edit && "Edit"}
            </DialogTitle>
            <DialogContent>
              {selectedImage && (
                <img
                  src={selectedImage}
                  alt="Full-size"
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              )}
              {edit && (
                <Box>
                  <TextField
                    label="Start Date"
                    fullWidth
                    value={startDate}
                    type="date"
                    onChange={handleStartDateChange}
                    sx={{ marginTop: "10px" }}
                  />
                  <TextField
                    label="End Date"
                    fullWidth
                    value={endDate}
                    type="date"
                    onChange={handleEndDateChange}
                    sx={{ marginTop: "10px" }}
                  />
                  <FormControl fullWidth sx={{ marginTop: "10px" }}>
                    <InputLabel id="demo-simple-select-label">
                      Status
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectData}
                      label="Status"
                      onChange={handleSelectChange}
                    >
                      <MenuItem value="Pending Review">Pending Review</MenuItem>
                      <MenuItem value="Verified">Verified</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    label="Comment"
                    fullWidth
                    onChange={handleCommentData}
                    sx={{ marginTop: "10px" }}
                  />
                </Box>
              )}
            </DialogContent>
            {edit && (
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSaveEdit}>Save</Button>
              </DialogActions>
            )}
          </Dialog>
        </Box>
      ) : page_lable === "code" ? (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box label="button container">
            {iPhoneModels.map((model, index) => (
              <Button
                key={index}
                color="primary"
                sx={{ marginRight: "20px" }}
                onClick={() => {
                  handleCodeButton(model);
                }}
              >
                {model}
              </Button>
            ))}
          </Box>
          <Divider />
          {/*upload file into backend  */}

          <Typography variant="h4" gutterBottom>
            Upload Excel File
          </Typography>

          <input
            accept=".xls,.xlsx"
            style={{ display: "none" }}
            id="upload-button"
            type="file"
            onChange={handleFileChange}
          />
          <label htmlFor="upload-button">
            <Button
              variant="contained"
              component="span"
              startIcon={<UploadFile />}
            >
              Select File
            </Button>
          </label>
          {selectedFile && (
            <Typography variant="body1">
              Selected {selectedFile.name}
            </Typography>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={handleFileUpload}
            disabled={!selectedFile || fileissUpload}
            sx={{ width: "150px" }}
          >
            {fileissUpload ? <CircularProgress size={24} /> : "Upload"}
          </Button>

          <TableContainer component={Paper} sx={{ marginTop: "70px" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Code</TableCell>
                  <TableCell>Create At</TableCell>
                  <TableCell>Used_At</TableCell>
                  <TableCell>model</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {console.log(typeof code)} */}
                {displayedData.length === 0 ? (
                  <TableRow>
                    <TableCell style={cellStyleForNone}>None</TableCell>
                  </TableRow>
                ) : (
                  displayedData.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.fields.code}</TableCell>
                      <TableCell>{row.fields.create_at}</TableCell>
                      <TableCell>
                        {row.fields.used_at === null
                          ? "None"
                          : row.fields.used_at}
                      </TableCell>
                      <TableCell>{row.fields.model}</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            totalPages={totalPages}
            currentPage={page}
            onPageChange={handlePageChange}
          />
        </Box>
      ) : (
        <Box>None</Box>
      )}
    </Box>
  );
}
export default InsuranceList;
