import React, { useEffect, useState } from "react";
import {
  Typography,
  IconButton,
  TextField,
  Chip,
  Box,
  Grid,
  Stack,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import { StyledTicketPageBox } from "../../common/globalStyles.component";

const CUSTOM_TASKS = [
  "Glass",
  "TP",
  "Backdoor",
  "LCD",
  "LCD-AM",
  "Camera",
  "Camera Lens",
  "Charging Port",
  "Battery",
  "Polish",
  "Home Button",
  "Speaker",
  "Frame",
  "Backlight",
];

export default function TaskSelector({ setTasks }) {
  const [input, setInput] = useState([]);
  const [otherTaskInput, setOtherTaskInput] = useState("");

  useEffect(() => {
    setTasks(input);
  }, [input, setTasks]);

  const addTask = (task) => {
    setInput([...input, task]);
  };

  const handleChipDelete = (index) => {
    const newData = [...input];
    newData.splice(index, 1);
    setInput(newData);
  };

  const AddTaskButton = ({ taskName }) => {
    return (
      <Grid item>
        <Box display="flex" alignItems="center" border="1px solid black">
          <Typography
            style={{
              backgroundColor: "#0D6EFD",
              color: "white",
              padding: "7px",
            }}
          >
            {taskName}
          </Typography>
          <IconButton
            onClick={() => {
              addTask(taskName);
            }}
          >
            <Add />
          </IconButton>
        </Box>
      </Grid>
    );
  };

  const handleOtherTask = () => {
    if (otherTaskInput.trim() !== "") {
      addTask(otherTaskInput);
      setOtherTaskInput("");
    }
  };

  return (
    <Grid container spacing={"1%"} style={{ paddingBottom: "1%" }}>
      <Grid item xs={12}>
        <Box style={{ display: "flex", width: "100%" }}>
          <StyledTicketPageBox>Tasks</StyledTicketPageBox>
          <TextField
            variant="outlined"
            style={{ flex: 1 }}
            InputProps={{
              startAdornment: (
                <Stack direction="row" spacing={1}>
                  {input.map((task, index) => (
                    <Chip
                      key={index + task}
                      label={task}
                      variant="outlined"
                      onDelete={() => handleChipDelete(index)}
                    />
                  ))}
                </Stack>
              ),
              readOnly: true,
            }}
          />
        </Box>
      </Grid>

      <Grid container item spacing={1}>
        {CUSTOM_TASKS.map((taskName, index) => (
          <AddTaskButton key={taskName + index} taskName={taskName} />
        ))}
        <Grid item>
          <Box border="1px solid black">
            <TextField
              size="small"
              label="Other"
              value={otherTaskInput}
              onChange={(e) => setOtherTaskInput(e.target.value)}
            />
            <IconButton
              onClick={(e) => {
                handleOtherTask();
              }}
            >
              <Add />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}
