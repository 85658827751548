import React, { useState } from "react";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import url from "../url.component";
import Cookies from "js-cookie";

function Note(props) {
  const { data, options, uuid } = props.state;
  const [message, setMessage] = useState("");
  const [text, setText] = useState(data.note_list.join("\n"));

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  const handleNoteChange = (event) => {
    event.preventDefault();
    fetch(url(options.UPDATE_NOTE.link), {
      method: "PUT",
      headers: { "X-CSRFToken": Cookies.get("csrftoken") },
      credentials: "include",
      body: JSON.stringify({ note_list: text, uuid: uuid }),
    })
      .then((res) => res.json())
      .then((res) => {
        setMessage(res.message);
      })
      .catch((error) => console.error(error));
  };

  return (
    <Card sx={{ width: "100%" }}>
      <CardContent>
        <TextField
          multiline
          rows={6}
          value={text}
          variant="outlined"
          fullWidth
          placeholder="For Admin Use Only"
          sx={{ fontSize: 32 }}
          onChange={handleTextChange}
        ></TextField>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
          }}
        >
          <Typography
            variant="body1"
            sx={{ marginBottom: "5px", color: "red", fontSize: "20px" }}
          >
            {message}
          </Typography>
          <Button
            variant="contained"
            onClick={handleNoteChange}
            sx={{ marginTop: 2 }}
          >
            UPDATE NOTE
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
}

export default Note;
